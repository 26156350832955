/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: avenir;
    src: url('assets/avenir.woff2');
}

:root {
    --backgroundColor: #fff;
    --backgroundColorDarker: #ddd;
    --headerBackgroundColor: #f0f8ff;
    --headerBackgroundShaded: #CCC6;
    --headerTextColor: #40435E;
    --dropdownTextColor: #f0f8ff;
    --bubblesHex: #e7feff;
    --darkText: #40435E;
    --realtimeBackgroundColor: #f8f4ff;
    --actionOptionActiveColor: #0029AF;
    --waitingColor: #FFDD1E;
    --warningColor: #FFDD1E;
    --successColor: #4BB210;
    --linkItemColor: #007bff;
    --errorOutline: #F00;
    --errorOutline: #F00;
    --errorBackground: #A00;
    --disabledGroup: #CCC6;
    --border: #40435E;
    --selected: #007bff66;
    --selectedHover: #007bffaa;
    --lightText: #aaa;
    --milestone-opacity:.5;
}

a {
    cursor: pointer !important;
}

body {
    color: var(--darkText);
    background: var(--backgroundColor);
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

label {
    font-weight: bold;
}

color-picker {
    width: 230px;
    height: 230px;
}

@media screen and (min-width: 840px) {
    color-picker {
        width: 350px;
        height: 350px;
    }
}

color-picker .color-palette {
    /* color wheel animation */
    transition-duration: .35s;
}

color-picker .color {
    /* selector (middle button) pulse animation */
    animation-duration: .28s;
    /* selector (middle button) appear/disappear */
    transition-duration: .49s;
}

color-picker .color-shadow-animate {
    /* ripple animation */
    animation-duration: .35s;
}

color-picker .knob {
    /* first property is for knob shadow on drag */
    /* second property is for knob appear/disappear */
    transition-duration: .2s, .28s;
}

.content>div {
    position: relative;
}

#activityRow {
    margin-top: 20px;
    margin-bottom: 10px;
}

#copyright {
    font-size: 10pt;
}

#iotimage {
    width: 80%;
}

#mainsubject {
    padding-top: 60px;
}

#mainsubject h2 img {
    height: 30px;
    vertical-align: bottom;
}

#real-time-notifications {
    margin-top: 40px;
    background-color: var(--realtimeBackgroundColor);
    padding: 10px;
}

#studentSummary {
    margin-top: 20px;
}

.chart-container {
  display: grid;
}
.chart-container>canvas {
  grid-column: 1;
  grid-row: 1;
}
.chart-container>svg {
  display:grid;
  position: unset !important;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  /* width: 100%; */
  top: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
}


.dropdown-menu:focus-within {
    display: inline-block;
}

.grey-out {
    background-color: lightgray;
    opacity: .8;
}

.hint {
    color: var(--darkText);
    font-weight: 0.9;
    font-style: italic;
}

.line-on-side {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.line-on-side:before,
.line-on-side:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: rgb(94, 94, 94);
}

.line-on-side:before {
    margin-left: -50%;
    text-align: right;
}

.line-on-side span {
    padding: 0 10px;
}

.action-option {
    border: var(--darkText) solid 1.5px;
    padding: 4px;
    border-radius: 5px;
    margin: 5px;
}

.action-option-selected {
    border: var(--actionOptionActiveColor) solid 3px;
    color: var(--actionOptionActiveColor) !important;
    padding: 4px;
    border-radius: 5px;
    margin: 5px;
}

.alert {
    font-size: 12pt;
    margin-top: 2px;
    padding: 3px;
    margin-bottom: 0px;
}

.alert-active {
    color: var(--actionOptionActiveColor);
}

.behavior-action-selection {
    margin-top: 20px;
    margin-bottom: 20px;
}

.behavior-action-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

.behavior-action-container label {
    font-weight: bold;
}

.behavior-collapse {
    max-height: 250px;
    overflow: hidden;
}

.bg-tt-primary {
    background-color: var(--headerBackgroundColor);
    color: var(--headerTextColor);
}

.big-symbol {
    font-size: 120pt;
    text-align: center;
}

.big-text {
    font-size: 20pt;
    margin-top: 30px;
}

.bubble {
    display: inline-block;
    position: absolute;
    font-family: 'avenir';
    font-weight: bold;
    font-size: 12pt;
    padding: 10px;
    border: 3px solid var(--darkText);
    background-color: var(--backgroundColor);
    border-radius: 15px;
    max-width: 300px;
}

.bubble h1 {
    text-align: center;
}

.btn {
    margin: 4px 2px;
}

.btn-menu {
    background-color: var(--headerBackgroundColor);
    color: var(--headerTextColor);
}

.btn-primary {
    color: var(--backgroundColor) !important;
}

.btn-secondary {
    color: var(--backgroundColor) !important;
}

.btn-success {
    color: var(--backgroundColor) !important;
}

.call-to-action {
    font-size: 20pt;
}

.closebox {
    position: absolute;
    right: 10px;
    top: 10px;
}

.colorbox {
    width: 70px;
    height: 70px;
    position: relative;
    margin: 20px 10px 20px 10px;
    padding: 10px 10px 0px 10px;
    border: var(--darkText) solid 2px;
    border-radius: 5px;
    display: inline-block;
}

.colorbox a {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
}

.container img {
    max-width: 100%;
}

#dashboardStudents {
    min-height: 100px !important;
}

.dashboard-students-collapse {
    max-height: 120px;
    overflow: hidden;
}

.dashboard-pannel {
    margin-top: 60px !important;
}

.dialog {
    margin-top: 200px;
    padding: 20px;
    background: var(--backgroundColor);
    border: var(--darkText) solid 4px;
    border-radius: 20px;
    position: relative;
    min-height: 430px;
}

.dialog img {
    max-width: 100%;
    border: solid 1px var(--darkText);
}

.dialog .list {
    max-height: 230px;
    overflow-y: scroll;
}

.dialog-actions {
    position: relative;
    margin-top: 50px;
    bottom: 10px;
    left: 0px;
    right: 0px;
}

.dialog-grey-out {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: var(--darkText);
}

.dialog-grey-out>a {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    cursor: default;
}

.dropdown {
    display: inline-block;
}

.footer {
    margin-top: -160px;
    padding-top: 20px;
    background-color: var(--headerBackgroundColor);
    min-height: 100px;
}

.footer a {
    font: normal normal normal 14px/1.4em avenir, sans-serif;
    color: #000000;
}

.footer>div>div {
    margin-bottom: 20px;
}

.footer h2 {
    font-size: 14pt;
}

.footer .social-icon {
    font-size: 16pt;
    margin-right: 7px;
}

.footer .seperator {
    border-top: 5px solid #000;
    margin-bottom: 30px;
}

.fullpage-dialog {
    min-height: 50%;
}

.graph {
    position: relative;
    border: var(--darkText) solid 2px;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.graph img {
    max-width: 100%;
}

.fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.graph .options {
    position: absolute;
    right: 5px;
    top: 5px;
}

.graph .options>div {
    position: relative;
}

.graph-new {
    position: relative;
    border: var(--darkText) dashed 2px;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.graph-new a {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.graph-new canvas {
    pointer-events: none;
}

.graph-title a {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
}

.group-highlight {
    border-radius: 10px;
    border: solid 2px var(--disabledGroup);
}

.help-button {
    color: var(--linkItemColor) !important;
}

.help-button:hover {
    text-decoration: underline !important;
}

.home-tagline-area {
    margin-bottom: 30px;
}

.home-tagline-image {
    height: 400px;
    background: url(assets/images/teacherandkid.png) no-repeat right bottom;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative
}

kendo-sortable > div{
    outline: none;
}

.large-spinner {
    font-size: 80pt;
    margin-top: 30px;
}

.large-spinner .fa-circle-o-notch {
    color: var(--waitingColor);
}

.large-spinner .fa-check {
    color: var(--successColor);
}

.link-item {
    color: var(--linkItemColor) !important;
}

.link-item:hover {
    text-decoration: underline !important;
}

.loading {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 10px;
    bottom: 0px;
    padding-top: 200px;
    background: var(--backgroundColor);
    z-index: 100;
}

.invite-large {
    margin-bottom: 20px;
    border: var(--darkText) 2px solid;
    border-radius: 10px;
    max-width: 80%;
    padding: 5px;
    background-color: var(--headerBackgroundColor);
}

.item-selected {
    border: var(--darkText) dashed 4px !important;
}

div.scroll-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 40px;
  font-size: 25px;
  z-index: 100;
  background-color: var(--backgroundColor);
}

div.scroll-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 40px;
  z-index: 100;
  background-color: var(--backgroundColor);
}

.milestone-graph {
  position: absolute;
}

.milestone line {
    opacity:var(--milestone-opacity);
}

.milestone rect {
    fill-opacity:var(--milestone-opacity);
}

.mytaptrack-header .col-12 {
    padding-right: 0px;
}

.navbar {
    background: var(--headerBackgroundColor) !important;
    color: var(--headerTextColor) !important;
}

.navbar-brand {
    color: var(--headerTextColor) !important;
    font-weight: 300;
    min-width: 120px;
}

.navbar-brand :hover {
    color: var(--headerTextColor) !important;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

.navbar-brand img {
    max-width: 30px;
    min-width: 120px;
}

.nav-item a {
    color: var(--headerTextColor) !important;
    margin-right: 10px;
    margin-left: 10px;
}

.nav-item a :hover {
    color: var(--headerTextColor);
    font-size: 12pt;
}

.notification-item {
    /*white-space: nowrap;*/
    font-size: 15pt;
    color: var(--headerTextColor);
    margin: 2px 15px 2px 15px;
}

.notification-item .btn {
    margin-right: 2px;
    padding: 2px 5px;
    vertical-align: text-top;
}

.progress {
    margin-bottom: 20px;
}

.regular-dropdown .dropdown-menu {
    background-color: var(--backgroundColor);
    width:300px;
}

.regular-dropdown .dropdown-item-multi-option {
    color: var(--headerTextColor);
    cursor: pointer;
}

.dropdown-item-multi-option .primary-option {
    width: 80%;
    display: inline-block;
    position: relative;
}

.secondary-option a {
    padding: 8px;
    vertical-align: bottom;
}

.primary-option a {
    display: block;
    padding: 5px;
}

.highlight-hover:hover {
    background-color: var(--headerBackgroundColor);
}

.report-options {
    margin: 5px;
}

.section {
    border: 2px solid var(--darkText);
    border-radius: 10px;
    margin-top: 5px;
}

.section .header {
    background-color: var(--darkText);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--backgroundColor);
}

.selected {
    text-decoration: underline !important;
    margin-right: -15px;
    margin-left: -10px;
    padding-left: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: solid 2px var(--headerTextColor);
    border-top: solid 2px var(--headerTextColor);
    border-bottom: solid 2px var(--headerTextColor);
    background-color: var(--headerBackgroundColor);
}

.select-details {
    background-color: var(--headerBackgroundColor);
    border: solid 2px var(--headerTextColor);
    min-height: 400px;
}


.social-media {
    font-size: 16pt;
}

.social-media a {
    margin-right: 5px;
}

.social-media img {
    height: 30px;
}

.spacer {
    margin-top: 5px;
}

.student-device {
    position: relative;
    border: var(--darkText) solid 2px;
    border-radius: 10px;
    margin: 2px;
    padding: 2px;
}

.student-device img {
    max-width: 100%;
}

.student-device-action label {
    font-weight: bold;
}

.student-device-action i {
    font-size: 8pt;
}

.student-device-new {
    position: relative;
    border: var(--darkText) dashed 2px;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.student-device-new a {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.student-device-new img {
    max-height: 150px;
}

.student-device-options {
    position: absolute;
    right: 5px;
    top: 5px;
}

.student-new {
    position: relative;
    border: var(--darkText) dashed 2px;
    border-radius: 10px;
    margin: 20px 10px 20px 10px;
    padding: 17px 10px 0px 10px;
}

.student-new a {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.student-new h3 {
    margin-bottom: 17px;
}

.area {
    min-height: 900px;
}

.student-device-title {
    font-size: 16pt;
    font-weight: bold;
}

.student-device-subtitle {
    font-size: 10pt;
}

.student-icon-large {
    font-size: 150px !important;
}

.student-selector {
    position: relative;
    margin: 20px 10px 20px 10px;
    padding: 10px 10px 0px 10px;
    border: var(--darkText) solid 2px;
    border-radius: 20px;
    background-color: var(--headerBackgroundColor);
    color: var(--darkText);
    overflow: hidden;
}

.student-selector :hover {
    background-color: var(--headerBackgroundShaded) !important;
    color: var(--darkText);
}

.student-selector i {
    font-size: 40pt;
    vertical-align: sub;
}

.student-selector>div {
    display: inline-block;
    margin-bottom: 5px;
}

.student-selector>div h3 {
    margin-bottom: 2px;
}

.student-selector .small {
    font-size: 80%;
}

.student-selector .icon-image-area {
    /*vertical-align: text-bottom;*/
    margin-right: 5px;
}

.student-selector .remove {
    position: absolute;
    display: inline-block;
    right: 5px;
    top: 5px;
    font-size: 15px;
}

.student-selector .remove i {
    font-size: 15px;
}

.student-selector-link {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.subheading {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    color: var(--darkText);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+43&0+0,1+51 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.84) 43%, var(--backgroundColor) 51%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.84) 43%, var(--backgroundColor) 51%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.84) 43%, var(--backgroundColor) 51%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr=var(--backgroundColor), GradientType=0);
    /* IE6-9 */
    padding-left: 40px;
    padding-top: 20px;
}

.subheading h1 {
    font-size: 40pt;
}

.subheading h1 img {
    height: 50px;
}

.support-team-box {
    border: 2px solid var(--darkText);
    border-radius: 10px;
    padding: 2px 10px;
    margin: 10px;
    display: inline-block;
    font-size: 14pt;
    width: 100px;
}

.support-team-box i {
    font-size: 60px;
}

.team-collapse {
    max-height: 220px;
    overflow: hidden;
}

ul {
    list-style-type: none;
}

.validation-error {
    border-color: var(--errorOutline);
    border-width: 2px;
}

.validation-error-text {
    color: var(--errorOutline);
    font-size: 14px;
}

.walkthrough {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: #0004
}

@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 25pt !important;
    }
    h1 img {
        height: 30px !important;
    }
    h2 {
        font-size: 15pt !important;
    }
    h4 {
        font-size: 12pt !important;
    }
    #mainsubject {
        margin-top: 0px;
    }
    .big-text {
        font-size: 12pt;
    }
    .iot-button-area {
        margin-left: auto;
        margin-right: auto;
    }
}

.container-main {
    min-height: 100%;
}

.container-inner {
    position: relative;
    padding-bottom: 180px;
}

.behavior-button-container {
    padding: 0 15px;
}

.view-area {
    position: relative;
}

.print-content {
    display: none !important;
}

@media print{
    .print-max {
        max-width:100% !important;
        flex: inherit !important;
        height: inherit !important;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }

    .print-content {
        display: block !important;
    }
}


/** These fonts are added in only temporarily until Angular has it's own theming system */

html {
font-family: var(--amplify-fonts-default-static);
}

@supports (font-variation-settings: normal) {
html {
    font-family: var(--amplify-fonts-default-variable);
}
}

.federated-sign-in-container {
    display: block !important;
    flex-direction: row !important;
    padding: 0 0 1rem 0;
    margin-bottom: 20px !important;
}

.federated-sign-in-button {
display: block;
}

.federated-sign-in-button-row {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
/*
 * Amplify UI Basic Theme
 */
/**
 * Do not edit directly
 * Generated on Wed, 26 Oct 2022 18:28:07 GMT
 */
 :root, [data-amplify-theme] {
    --amplify-transforms-slide-x-large: translateX(2em);
    --amplify-transforms-slide-x-medium: translateX(1em);
    --amplify-transforms-slide-x-small: translateX(0.5em);
    --amplify-time-long: 500ms;
    --amplify-time-medium: 250ms;
    --amplify-time-short: 100ms;
    --amplify-space-relative-full: 100%;
    --amplify-space-relative-xxxl: 4.5em;
    --amplify-space-relative-xxl: 3.0em;
    --amplify-space-relative-xl: 2.0em;
    --amplify-space-relative-large: 1.5em;
    --amplify-space-relative-medium: 1em;
    --amplify-space-relative-small: 0.75em;
    --amplify-space-relative-xs: 0.5em;
    --amplify-space-relative-xxs: 0.375em;
    --amplify-space-relative-xxxs: 0.25em;
    --amplify-space-xxxl: 4.5rem;
    --amplify-space-xxl: 3.0rem;
    --amplify-space-xl: 2.0rem;
    --amplify-space-large: 1.5rem;
    --amplify-space-medium: 1rem;
    --amplify-space-small: 0.75rem;
    --amplify-space-xs: 0.5rem;
    --amplify-space-xxs: 0.375rem;
    --amplify-space-xxxs: 0.25rem;
    --amplify-space-zero: 0;
    --amplify-radii-xxxl: 8rem;
    --amplify-radii-xxl: 4rem;
    --amplify-radii-xl: 2rem;
    --amplify-radii-large: 1rem;
    --amplify-radii-medium: 0.5rem;
    --amplify-radii-small: 0.25rem;
    --amplify-radii-xs: 0.125rem;
    --amplify-outline-widths-large: 3px;
    --amplify-outline-widths-medium: 2px;
    --amplify-outline-widths-small: 1px;
    --amplify-outline-offsets-large: 3px;
    --amplify-outline-offsets-medium: 2px;
    --amplify-outline-offsets-small: 1px;
    --amplify-opacities-100: 1;
    --amplify-opacities-90: 0.9;
    --amplify-opacities-80: 0.8;
    --amplify-opacities-70: 0.7;
    --amplify-opacities-60: 0.6;
    --amplify-opacities-50: 0.5;
    --amplify-opacities-40: 0.4;
    --amplify-opacities-30: 0.3;
    --amplify-opacities-20: 0.2;
    --amplify-opacities-10: 0.1;
    --amplify-opacities-0: 0;
    --amplify-line-heights-large: 2;
    --amplify-line-heights-medium: 1.5;
    --amplify-line-heights-small: 1.25;
    --amplify-font-weights-black: 900;
    --amplify-font-weights-extrabold: 800;
    --amplify-font-weights-bold: 700;
    --amplify-font-weights-semibold: 600;
    --amplify-font-weights-medium: 500;
    --amplify-font-weights-normal: 400;
    --amplify-font-weights-light: 300;
    --amplify-font-weights-thin: 200;
    --amplify-font-weights-hairline: 100;
    --amplify-font-sizes-xxxxl: 3rem;
    --amplify-font-sizes-xxxl: 2.5rem;
    --amplify-font-sizes-xxl: 2rem;
    --amplify-font-sizes-xl: 1.5rem;
    --amplify-font-sizes-large: 1.25rem;
    --amplify-font-sizes-medium: 1rem;
    --amplify-font-sizes-small: 0.875rem;
    --amplify-font-sizes-xs: 0.75rem;
    --amplify-font-sizes-xxs: 0.5rem;
    --amplify-font-sizes-xxxs: 0.375rem;
    --amplify-fonts-default-static: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
          "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
    --amplify-fonts-default-variable: "InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
          "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
          sans-serif;
    --amplify-colors-transparent: transparent;
    --amplify-colors-white: hsl(0, 0%, 100%);
    --amplify-colors-black: hsl(0, 0%, 0%);
    --amplify-colors-overlay-90: hsla(0, 0%, 0%, 0.9);
    --amplify-colors-overlay-80: hsla(0, 0%, 0%, 0.8);
    --amplify-colors-overlay-70: hsla(0, 0%, 0%, 0.7);
    --amplify-colors-overlay-60: hsla(0, 0%, 0%, 0.6);
    --amplify-colors-overlay-50: hsla(0, 0%, 0%, 0.5);
    --amplify-colors-overlay-40: hsla(0, 0%, 0%, 0.4);
    --amplify-colors-overlay-30: hsla(0, 0%, 0%, 0.3);
    --amplify-colors-overlay-20: hsla(0, 0%, 0%, 0.2);
    --amplify-colors-overlay-10: hsla(0, 0%, 0%, 0.1);
    --amplify-colors-shadow-tertiary: hsla(210, 50%, 10%, 0.05);
    --amplify-colors-shadow-secondary: hsla(210, 50%, 10%, 0.15);
    --amplify-colors-shadow-primary: hsla(210, 50%, 10%, 0.25);
    --amplify-colors-neutral-100: hsl(210, 50%, 10%);
    --amplify-colors-neutral-90: hsl(210, 25%, 25%);
    --amplify-colors-neutral-80: hsl(210, 10%, 40%);
    --amplify-colors-neutral-60: hsl(210, 8%, 55%);
    --amplify-colors-neutral-40: hsl(210, 5%, 87%);
    --amplify-colors-neutral-20: hsl(210, 5%, 94%);
    --amplify-colors-neutral-10: hsl(210, 5%, 98%);
    --amplify-colors-pink-100: hsl(340, 100%, 15%);
    --amplify-colors-pink-90: hsl(340, 100%, 20%);
    --amplify-colors-pink-80: hsl(340, 95%, 30%);
    --amplify-colors-pink-60: hsl(340, 50%, 50%);
    --amplify-colors-pink-40: hsl(340, 70%, 70%);
    --amplify-colors-pink-20: hsl(340, 90%, 85%);
    --amplify-colors-pink-10: hsl(340, 95%, 95%);
    --amplify-colors-purple-100: hsl(300, 100%, 15%);
    --amplify-colors-purple-90: hsl(300, 100%, 20%);
    --amplify-colors-purple-80: hsl(300, 95%, 30%);
    --amplify-colors-purple-60: hsl(300, 50%, 50%);
    --amplify-colors-purple-40: hsl(300, 70%, 70%);
    --amplify-colors-purple-20: hsl(300, 85%, 85%);
    --amplify-colors-purple-10: hsl(300, 95%, 95%);
    --amplify-colors-blue-100: hsl(220, 100%, 15%);
    --amplify-colors-blue-90: var(--actionOptionActiveColor);
    --amplify-colors-blue-80: hsl(220, 95%, 30%);
    --amplify-colors-blue-60: hsl(220, 50%, 50%);
    --amplify-colors-blue-40: hsl(220, 70%, 70%);
    --amplify-colors-blue-20: hsl(220, 85%, 85%);
    --amplify-colors-blue-10: hsl(220, 95%, 95%);
    --amplify-colors-teal-100: var(--amplify-colors-blue-100);
    --amplify-colors-teal-90: var(--amplify-colors-blue-90);
    --amplify-colors-teal-80: var(--amplify-colors-blue-80);
    --amplify-colors-teal-60: var(--amplify-colors-blue-60);
    --amplify-colors-teal-40: var(--amplify-colors-blue-40);
    --amplify-colors-teal-20: var(--amplify-colors-blue-20);
    --amplify-colors-teal-10: var(--amplify-colors-blue-10);
    --amplify-colors-green-100: hsl(130, 22%, 23%);
    --amplify-colors-green-90: hsl(130, 27%, 29%);
    --amplify-colors-green-80: hsl(130, 33%, 37%);
    --amplify-colors-green-60: hsl(130, 43%, 46%);
    --amplify-colors-green-40: hsl(130, 44%, 63%);
    --amplify-colors-green-20: hsl(130, 60%, 90%);
    --amplify-colors-green-10: hsl(130, 60%, 95%);
    --amplify-colors-yellow-100: hsl(60, 100%, 15%);
    --amplify-colors-yellow-90: hsl(60, 100%, 20%);
    --amplify-colors-yellow-80: hsl(60, 95%, 30%);
    --amplify-colors-yellow-60: hsl(60, 50%, 50%);
    --amplify-colors-yellow-40: hsl(60, 75%, 75%);
    --amplify-colors-yellow-20: hsl(60, 75%, 85%);
    --amplify-colors-yellow-10: hsl(60, 75%, 95%);
    --amplify-colors-orange-100: hsl(30, 100%, 15%);
    --amplify-colors-orange-90: hsl(30, 100%, 20%);
    --amplify-colors-orange-80: hsl(30, 95%, 30%);
    --amplify-colors-orange-60: hsl(30, 50%, 50%);
    --amplify-colors-orange-40: hsl(30, 75%, 75%);
    --amplify-colors-orange-20: hsl(30, 75%, 85%);
    --amplify-colors-orange-10: hsl(30, 75%, 95%);
    --amplify-colors-red-100: hsl(0, 100%, 15%);
    --amplify-colors-red-90: hsl(0, 100%, 20%);
    --amplify-colors-red-80: hsl(0, 95%, 30%);
    --amplify-colors-red-60: hsl(0, 50%, 50%);
    --amplify-colors-red-40: hsl(0, 75%, 75%);
    --amplify-colors-red-20: hsl(0, 75%, 85%);
    --amplify-colors-red-10: hsl(0, 75%, 95%);
    --amplify-border-widths-large: 3px;
    --amplify-border-widths-medium: 2px;
    --amplify-border-widths-small: 1px;
    --amplify-components-togglebuttongroup-justify-content: flex-start;
    --amplify-components-togglebuttongroup-align-content: center;
    --amplify-components-togglebuttongroup-align-items: center;
    --amplify-components-tabs-item-disabled-background-color: transparent;
    --amplify-components-tabs-item-active-background-color: transparent;
    --amplify-components-tabs-item-text-align: center;
    --amplify-components-tabs-item-border-style: solid;
    --amplify-components-tabs-item-background-color: transparent;
    --amplify-components-tabs-gap: 0;
    --amplify-components-tabs-border-style: solid;
    --amplify-components-tabs-background-color: transparent;
    --amplify-components-table-caption-word-break: break-all;
    --amplify-components-table-caption-text-align: center;
    --amplify-components-table-caption-display: table-caption;
    --amplify-components-table-caption-caption-side: bottom;
    --amplify-components-table-data-vertical-align: middle;
    --amplify-components-table-data-display: table-cell;
    --amplify-components-table-data-border-style: solid;
    --amplify-components-table-header-vertical-align: middle;
    --amplify-components-table-header-display: table-cell;
    --amplify-components-table-header-border-style: solid;
    --amplify-components-table-row-vertical-align: middle;
    --amplify-components-table-row-display: table-row;
    --amplify-components-table-foot-vertical-align: middle;
    --amplify-components-table-foot-display: table-footer-group;
    --amplify-components-table-body-vertical-align: middle;
    --amplify-components-table-body-display: table-row-group;
    --amplify-components-table-head-vertical-align: middle;
    --amplify-components-table-head-display: table-header-group;
    --amplify-components-table-width: 100%;
    --amplify-components-table-display: table;
    --amplify-components-table-border-collapse: collapse;
    --amplify-components-stepperfield-input-text-align: center;
    --amplify-components-stepperfield-flex-direction: column;
    --amplify-components-sliderfield-large-thumb-height: 1.5rem;
    --amplify-components-sliderfield-large-thumb-width: 1.5rem;
    --amplify-components-sliderfield-large-track-height: 0.625rem;
    --amplify-components-sliderfield-small-thumb-height: 1rem;
    --amplify-components-sliderfield-small-thumb-width: 1rem;
    --amplify-components-sliderfield-small-track-height: 0.25rem;
    --amplify-components-sliderfield-thumb-disabled-box-shadow: none;
    --amplify-components-sliderfield-thumb-disabled-border-color: transparent;
    --amplify-components-sliderfield-thumb-border-style: solid;
    --amplify-components-sliderfield-thumb-border-radius: 50%;
    --amplify-components-sliderfield-thumb-height: 1.25rem;
    --amplify-components-sliderfield-thumb-width: 1.25rem;
    --amplify-components-sliderfield-range-border-radius: 9999px;
    --amplify-components-sliderfield-track-min-width: 10rem;
    --amplify-components-sliderfield-track-height: 0.375rem;
    --amplify-components-sliderfield-track-border-radius: 9999px;
    --amplify-components-selectfield-flex-direction: column;
    --amplify-components-select-large-min-width: 7.5rem;
    --amplify-components-select-small-min-width: 5.5rem;
    --amplify-components-select-min-width: 6.5rem;
    --amplify-components-select-white-space: nowrap;
    --amplify-components-select-icon-wrapper-pointer-events: none;
    --amplify-components-select-icon-wrapper-transform: translateY(-50%);
    --amplify-components-select-icon-wrapper-top: 50%;
    --amplify-components-select-icon-wrapper-position: absolute;
    --amplify-components-select-icon-wrapper-align-items: center;
    --amplify-components-select-wrapper-cursor: pointer;
    --amplify-components-select-wrapper-position: relative;
    --amplify-components-select-wrapper-display: block;
    --amplify-components-select-wrapper-flex: 1;
    --amplify-components-radio-button-outline-style: solid;
    --amplify-components-radio-button-transition-property: all;
    --amplify-components-radio-button-border-radius: 50%;
    --amplify-components-radio-button-border-style: solid;
    --amplify-components-radio-button-box-sizing: border-box;
    --amplify-components-radio-button-justify-content: center;
    --amplify-components-radio-button-align-items: center;
    --amplify-components-radio-disabled-cursor: not-allowed;
    --amplify-components-radio-gap: inherit;
    --amplify-components-radio-justify-content: flex-start;
    --amplify-components-radio-align-items: center;
    --amplify-components-pagination-ellipsis-justify-content: center;
    --amplify-components-pagination-ellipsis-align-items: baseline;
    --amplify-components-pagination-button-transition-property: background-color;
    --amplify-components-pagination-current-justify-content: center;
    --amplify-components-pagination-current-align-items: center;
    --amplify-components-menu-item-min-height: 2.5rem;
    --amplify-components-menu-min-width: 14rem;
    --amplify-components-menu-max-width: 30rem;
    --amplify-components-menu-flex-direction: column;
    --amplify-components-menu-border-style: solid;
    --amplify-components-loader-linear-animation-duration: 1s;
    --amplify-components-loader-linear-stroke-linecap: round;
    --amplify-components-loader-linear-min-width: 5rem;
    --amplify-components-loader-linear-width: 100%;
    --amplify-components-loader-animation-duration: 1s;
    --amplify-components-loader-stroke-linecap: round;
    --amplify-components-image-object-position: initial;
    --amplify-components-image-object-fit: initial;
    --amplify-components-image-height: auto;
    --amplify-components-image-max-width: 100%;
    --amplify-components-icon-height: 1em;
    --amplify-components-icon-line-height: 1;
    --amplify-components-flex-flex-wrap: nowrap;
    --amplify-components-flex-align-content: normal;
    --amplify-components-flex-align-items: stretch;
    --amplify-components-flex-justify-content: normal;
    --amplify-components-fieldmessages-description-font-style: italic;
    --amplify-components-fieldgroup-outer-align-items: center;
    --amplify-components-fieldgroup-vertical-align-items: center;
    --amplify-components-fieldcontrol-disabled-cursor: not-allowed;
    --amplify-components-fieldcontrol-quiet-border-radius: 0;
    --amplify-components-fieldcontrol-quiet-border-block-start: none;
    --amplify-components-fieldcontrol-quiet-border-inline-end: none;
    --amplify-components-fieldcontrol-quiet-border-inline-start: none;
    --amplify-components-fieldcontrol-quiet-border-style: none;
    --amplify-components-fieldcontrol-outline-style: solid;
    --amplify-components-fieldcontrol-border-style: solid;
    --amplify-components-expander-icon-transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
    --amplify-components-expander-content-closed-animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
    --amplify-components-expander-content-open-animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
    --amplify-components-expander-trigger-justify-content: space-between;
    --amplify-components-expander-trigger-align-items: center;
    --amplify-components-expander-trigger-min-height: 3rem;
    --amplify-components-expander-item-margin-top: 1px;
    --amplify-components-expander-width: 100%;
    --amplify-components-expander-display: block;
    --amplify-components-divider-border-style: solid;
    --amplify-components-checkboxfield-justify-content: center;
    --amplify-components-checkboxfield-flex-direction: column;
    --amplify-components-checkboxfield-align-content: center;
    --amplify-components-checkboxfield-align-items: flex-start;
    --amplify-components-checkbox-icon-indeterminate-transform: scale(1);
    --amplify-components-checkbox-icon-checked-transform: scale(1);
    --amplify-components-checkbox-icon-transition-timing-function: ease-in-out;
    --amplify-components-checkbox-icon-transition-property: all;
    --amplify-components-checkbox-icon-transform: scale(0);
    --amplify-components-checkbox-icon-border-radius: 20%;
    --amplify-components-checkbox-button-focus-outline-style: solid;
    --amplify-components-checkbox-button-before-border-style: solid;
    --amplify-components-checkbox-button-before-border-radius: 20%;
    --amplify-components-checkbox-button-before-height: 100%;
    --amplify-components-checkbox-button-before-width: 100%;
    --amplify-components-checkbox-button-justify-content: center;
    --amplify-components-checkbox-button-align-items: center;
    --amplify-components-checkbox-button-position: relative;
    --amplify-components-checkbox-disabled-cursor: not-allowed;
    --amplify-components-checkbox-align-items: center;
    --amplify-components-checkbox-cursor: pointer;
    --amplify-components-card-elevated-border-color: transparent;
    --amplify-components-card-elevated-border-style: solid;
    --amplify-components-card-elevated-border-width: 0;
    --amplify-components-card-outlined-border-style: solid;
    --amplify-components-card-box-shadow: none;
    --amplify-components-card-border-color: transparent;
    --amplify-components-card-border-style: solid;
    --amplify-components-card-border-width: 0;
    --amplify-components-button-loader-wrapper-align-items: center;
    --amplify-components-button-link-loading-background-color: transparent;
    --amplify-components-button-link-loading-border-color: transparent;
    --amplify-components-button-link-disabled-background-color: transparent;
    --amplify-components-button-link-disabled-border-color: transparent;
    --amplify-components-button-link-active-border-color: transparent;
    --amplify-components-button-link-focus-border-color: transparent;
    --amplify-components-button-link-hover-border-color: transparent;
    --amplify-components-button-link-border-color: transparent;
    --amplify-components-button-link-background-color: transparent;
    --amplify-components-button-menu-justify-content: start;
    --amplify-components-button-menu-background-color: transparent;
    --amplify-components-button-primary-active-border-color: transparent;
    --amplify-components-button-primary-focus-border-color: transparent;
    --amplify-components-button-primary-hover-border-color: transparent;
    --amplify-components-button-primary-loading-border-color: transparent;
    --amplify-components-button-primary-disabled-border-color: transparent;
    --amplify-components-button-primary-border-style: solid;
    --amplify-components-button-primary-border-color: transparent;
    --amplify-components-button-disabled-background-color: transparent;
    --amplify-components-button-loading-background-color: transparent;
    --amplify-components-badge-text-align: center;
    --amplify-components-badge-line-height: 1;
    --amplify-components-authenticator-router-border-style: solid;
    --amplify-components-authenticator-container-width-max: 30rem;
    --amplify-components-authenticator-max-width: 60rem;
    --amplify-components-alert-justify-content: space-between;
    --amplify-components-alert-align-items: center;
    --amplify-shadows-large: 0px 4px 12px var(--amplify-colors-shadow-primary);
    --amplify-shadows-medium: 0px 2px 6px var(--amplify-colors-shadow-secondary);
    --amplify-shadows-small: 0px 2px 4px var(--amplify-colors-shadow-tertiary);
    --amplify-colors-border-error: var(--amplify-colors-red-80);
    --amplify-colors-border-tertiary: var(--amplify-colors-neutral-20);
    --amplify-colors-border-secondary: var(--amplify-colors-neutral-40);
    --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
    --amplify-colors-background-success: var(--amplify-colors-green-20);
    --amplify-colors-background-error: var(--amplify-colors-red-20);
    --amplify-colors-background-warning: var(--amplify-colors-orange-20);
    --amplify-colors-background-info: var(--amplify-colors-blue-20);
    --amplify-colors-background-quaternary: var(--amplify-colors-neutral-60);
    --amplify-colors-background-tertiary: var(--amplify-colors-neutral-20);
    --amplify-colors-background-secondary: var(--amplify-colors-neutral-10);
    --amplify-colors-background-primary: var(--amplify-colors-white);
    --amplify-colors-font-success: var(--amplify-colors-green-90);
    --amplify-colors-font-error: var(--amplify-colors-red-90);
    --amplify-colors-font-warning: var(--amplify-colors-orange-90);
    --amplify-colors-font-info: var(--amplify-colors-blue-90);
    --amplify-colors-font-inverse: var(--amplify-colors-white);
    --amplify-colors-font-disabled: var(--amplify-colors-neutral-60);
    --amplify-colors-font-tertiary: var(--amplify-colors-neutral-80);
    --amplify-colors-font-secondary: var(--amplify-colors-neutral-90);
    --amplify-colors-font-primary: var(--amplify-colors-neutral-100);
    --amplify-colors-brand-secondary-100: var(--amplify-colors-purple-100);
    --amplify-colors-brand-secondary-90: var(--amplify-colors-purple-90);
    --amplify-colors-brand-secondary-80: var(--amplify-colors-purple-80);
    --amplify-colors-brand-secondary-60: var(--amplify-colors-purple-60);
    --amplify-colors-brand-secondary-40: var(--amplify-colors-purple-40);
    --amplify-colors-brand-secondary-20: var(--amplify-colors-purple-20);
    --amplify-colors-brand-secondary-10: var(--amplify-colors-purple-10);
    --amplify-colors-brand-primary-100: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-90);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-80);
    --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
    --amplify-colors-brand-primary-40: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-20: var(--amplify-colors-teal-20);
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-10);
    --amplify-components-togglebutton-link-pressed-hover-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-pressed-hover-color: var(--amplify-colors-overlay-90);
    --amplify-components-togglebutton-link-pressed-focus-color: var(--amplify-colors-overlay-90);
    --amplify-components-togglebutton-link-pressed-focus-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-pressed-color: var(--amplify-colors-overlay-90);
    --amplify-components-togglebutton-link-pressed-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-disabled-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-focus-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-link-focus-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-hover-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-link-hover-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-link-color: var(--amplify-colors-overlay-50);
    --amplify-components-togglebutton-link-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-primary-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-togglebutton-primary-focus-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-primary-border-width: var(--amplify-border-widths-small);
    --amplify-components-togglebutton-primary-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-pressed-hover-background-color: var(--amplify-colors-overlay-30);
    --amplify-components-togglebutton-pressed-background-color: var(--amplify-colors-overlay-20);
    --amplify-components-togglebutton-disabled-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-active-background-color: var(--amplify-colors-transparent);
    --amplify-components-togglebutton-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-tabs-item-transition-duration: var(--amplify-time-medium);
    --amplify-components-tabs-item-padding-horizontal: var(--amplify-space-medium);
    --amplify-components-tabs-item-padding-vertical: var(--amplify-space-small);
    --amplify-components-tabs-item-font-weight: 700;
    --amplify-components-tabs-item-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-tabs-item-border-width: var(--amplify-border-widths-medium);
    --amplify-components-tabs-border-width: var(--amplify-border-widths-medium);
    --amplify-components-table-caption-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-caption-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-caption-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-data-small-padding: var(--amplify-space-xs);
    --amplify-components-table-data-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-data-large-padding: var(--amplify-space-large);
    --amplify-components-table-data-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-data-padding: var(--amplify-space-medium);
    --amplify-components-table-data-font-weight: 400;
    --amplify-components-table-data-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-data-border-width: var(--amplify-border-widths-small);
    --amplify-components-table-header-small-padding: var(--amplify-space-xs);
    --amplify-components-table-header-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-table-header-large-padding: var(--amplify-space-large);
    --amplify-components-table-header-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-table-header-padding: var(--amplify-space-medium);
    --amplify-components-table-header-font-weight: 700;
    --amplify-components-table-header-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-table-header-border-width: var(--amplify-border-widths-small);
    --amplify-components-switchfield-track-width: var(--amplify-space-relative-xl);
    --amplify-components-switchfield-track-transition-duration: var(--amplify-time-short);
    --amplify-components-switchfield-track-padding: var(--amplify-outline-widths-medium);
    --amplify-components-switchfield-track-height: var(--amplify-space-relative-medium);
    --amplify-components-switchfield-track-border-radius: var(--amplify-radii-xxxl);
    --amplify-components-switchfield-thumb-width: var(--amplify-space-relative-medium);
    --amplify-components-switchfield-thumb-transition-duration: var(--amplify-time-medium);
    --amplify-components-switchfield-thumb-checked-transform: var(--amplify-transforms-slide-x-medium);
    --amplify-components-switchfield-thumb-border-radius: var(--amplify-radii-xxxl);
    --amplify-components-switchfield-label-padding: var(--amplify-space-xs);
    --amplify-components-switchfield-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-switchfield-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-switchfield-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-switchfield-disabled-opacity: var(--amplify-opacities-60);
    --amplify-components-stepperfield-button-background-color: var(--amplify-colors-transparent);
    --amplify-components-sliderfield-thumb-border-width: var(--amplify-border-widths-medium);
    --amplify-components-sliderfield-padding-block: var(--amplify-space-xs);
    --amplify-components-select-icon-wrapper-right: var(--amplify-space-medium);
    --amplify-components-select-padding-inline-end: var(--amplify-space-xxl);
    --amplify-components-searchfield-button-disabled-background-color: var(--amplify-components-button-disabled-background-color);
    --amplify-components-rating-small-size: var(--amplify-font-sizes-small);
    --amplify-components-rating-default-size: var(--amplify-font-sizes-xl);
    --amplify-components-rating-large-size: var(--amplify-font-sizes-xxxl);
    --amplify-components-radio-button-large-height: var(--amplify-font-sizes-large);
    --amplify-components-radio-button-large-width: var(--amplify-font-sizes-large);
    --amplify-components-radio-button-small-height: var(--amplify-font-sizes-small);
    --amplify-components-radio-button-small-width: var(--amplify-font-sizes-small);
    --amplify-components-radio-button-padding: var(--amplify-border-widths-medium);
    --amplify-components-radio-button-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-radio-button-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-radio-button-outline-color: var(--amplify-colors-transparent);
    --amplify-components-radio-button-transition-duration: var(--amplify-time-medium);
    --amplify-components-radio-button-border-width: var(--amplify-border-widths-medium);
    --amplify-components-radio-button-height: var(--amplify-font-sizes-medium);
    --amplify-components-radio-button-width: var(--amplify-font-sizes-medium);
    --amplify-components-placeholder-large-height: var(--amplify-space-large);
    --amplify-components-placeholder-default-height: var(--amplify-space-medium);
    --amplify-components-placeholder-small-height: var(--amplify-space-small);
    --amplify-components-placeholder-transition-duration: var(--amplify-time-long);
    --amplify-components-placeholder-border-radius: var(--amplify-radii-small);
    --amplify-components-passwordfield-button-disabled-background-color: var(--amplify-components-button-disabled-background-color);
    --amplify-components-pagination-item-shared-border-radius: var(--amplify-font-sizes-medium);
    --amplify-components-pagination-item-shared-min-width: var(--amplify-font-sizes-xxl);
    --amplify-components-pagination-item-shared-height: var(--amplify-font-sizes-xxl);
    --amplify-components-pagination-item-container-margin-right: var(--amplify-space-xxxs);
    --amplify-components-pagination-item-container-margin-left: var(--amplify-space-xxxs);
    --amplify-components-pagination-ellipsis-padding-inline-end: var(--amplify-space-xs);
    --amplify-components-pagination-ellipsis-padding-inline-start: var(--amplify-space-xs);
    --amplify-components-pagination-button-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-pagination-button-transition-duration: var(--amplify-time-medium);
    --amplify-components-pagination-button-padding-inline-end: var(--amplify-space-xxs);
    --amplify-components-pagination-button-padding-inline-start: var(--amplify-space-xxs);
    --amplify-components-pagination-current-background-color: var(--amplify-colors-overlay-40);
    --amplify-components-pagination-current-font-size: var(--amplify-font-sizes-small);
    --amplify-components-menu-item-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-menu-item-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-menu-large-height: var(--amplify-font-sizes-xxxl);
    --amplify-components-menu-large-width: var(--amplify-font-sizes-xxxl);
    --amplify-components-menu-small-height: var(--amplify-font-sizes-medium);
    --amplify-components-menu-small-width: var(--amplify-font-sizes-medium);
    --amplify-components-menu-gap: var(--amplify-space-zero);
    --amplify-components-menu-border-width: var(--amplify-border-widths-small);
    --amplify-components-menu-border-radius: var(--amplify-radii-medium);
    --amplify-components-loader-linear-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-loader-linear-large-stroke-width: var(--amplify-font-sizes-large);
    --amplify-components-loader-linear-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-loader-linear-small-stroke-width: var(--amplify-font-sizes-small);
    --amplify-components-loader-linear-stroke-empty: var(--amplify-colors-neutral-20);
    --amplify-components-loader-linear-stroke-width: var(--amplify-font-sizes-medium);
    --amplify-components-loader-linear-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-loader-large-font-size: var(--amplify-font-sizes-small);
    --amplify-components-loader-large-height: var(--amplify-font-sizes-large);
    --amplify-components-loader-large-width: var(--amplify-font-sizes-large);
    --amplify-components-loader-small-font-size: var(--amplify-font-sizes-xxs);
    --amplify-components-loader-small-height: var(--amplify-font-sizes-small);
    --amplify-components-loader-small-width: var(--amplify-font-sizes-small);
    --amplify-components-loader-stroke-empty: var(--amplify-colors-neutral-20);
    --amplify-components-loader-font-size: var(--amplify-font-sizes-xs);
    --amplify-components-loader-height: var(--amplify-font-sizes-medium);
    --amplify-components-loader-width: var(--amplify-font-sizes-medium);
    --amplify-components-heading-line-height: var(--amplify-line-heights-small);
    --amplify-components-heading-6-font-weight: 800;
    --amplify-components-heading-6-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-heading-5-font-weight: 700;
    --amplify-components-heading-5-font-size: var(--amplify-font-sizes-large);
    --amplify-components-heading-4-font-weight: 600;
    --amplify-components-heading-4-font-size: var(--amplify-font-sizes-xl);
    --amplify-components-heading-3-font-weight: 500;
    --amplify-components-heading-3-font-size: var(--amplify-font-sizes-xxl);
    --amplify-components-heading-2-font-weight: 400;
    --amplify-components-heading-2-font-size: var(--amplify-font-sizes-xxxl);
    --amplify-components-heading-1-font-weight: 300;
    --amplify-components-heading-1-font-size: var(--amplify-font-sizes-xxxxl);
    --amplify-components-flex-gap: var(--amplify-space-medium);
    --amplify-components-fieldmessages-description-font-size: var(--amplify-font-sizes-small);
    --amplify-components-fieldmessages-error-font-size: var(--amplify-font-sizes-small);
    --amplify-components-fieldgroup-gap: var(--amplify-space-zero);
    --amplify-components-fieldcontrol-disabled-border-color: var(--amplify-colors-transparent);
    --amplify-components-fieldcontrol-quiet-border-block-end: var(--amplify-border-widths-small) solid var(--amplify-colors-border-primary);
    --amplify-components-fieldcontrol-large-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-large-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-large-padding-block-end: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-large-padding-block-start: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-small-padding-inline-end: var(--amplify-space-small);
    --amplify-components-fieldcontrol-small-padding-inline-start: var(--amplify-space-small);
    --amplify-components-fieldcontrol-small-padding-block-end: var(--amplify-space-xxs);
    --amplify-components-fieldcontrol-small-padding-block-start: var(--amplify-space-xxs);
    --amplify-components-fieldcontrol-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-fieldcontrol-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-fieldcontrol-outline-color: var(--amplify-colors-transparent);
    --amplify-components-fieldcontrol-transition-duration: var(--amplify-time-medium);
    --amplify-components-fieldcontrol-line-height: var(--amplify-line-heights-medium);
    --amplify-components-fieldcontrol-padding-inline-end: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-padding-inline-start: var(--amplify-space-medium);
    --amplify-components-fieldcontrol-padding-block-end: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-padding-block-start: var(--amplify-space-xs);
    --amplify-components-fieldcontrol-border-radius: var(--amplify-radii-small);
    --amplify-components-fieldcontrol-border-width: var(--amplify-border-widths-small);
    --amplify-components-field-large-font-size: var(--amplify-font-sizes-large);
    --amplify-components-field-large-gap: var(--amplify-space-small);
    --amplify-components-field-small-font-size: var(--amplify-font-sizes-small);
    --amplify-components-field-small-gap: var(--amplify-space-xxxs);
    --amplify-components-field-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-field-gap: var(--amplify-space-xs);
    --amplify-components-expander-icon-transition-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-closed-animation-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-open-animation-duration: var(--amplify-time-medium);
    --amplify-components-expander-content-text-padding-block-end: var(--amplify-space-medium);
    --amplify-components-expander-content-text-padding-block-start: var(--amplify-space-medium);
    --amplify-components-expander-content-padding-inline-end: var(--amplify-space-large);
    --amplify-components-expander-content-padding-inline-start: var(--amplify-space-large);
    --amplify-components-expander-trigger-hover-background-color: var(--amplify-colors-overlay-10);
    --amplify-components-expander-trigger-padding-inline-end: var(--amplify-space-large);
    --amplify-components-expander-trigger-padding-inline-start: var(--amplify-space-large);
    --amplify-components-expander-header-box-shadow: 0 1px 0 var(--amplify-colors-overlay-20);
    --amplify-components-expander-item-border-end-end-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-end-start-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-start-end-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-start-start-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-top-right-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-top-left-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-bottom-right-radius: var(--amplify-radii-medium);
    --amplify-components-expander-item-border-bottom-left-radius: var(--amplify-radii-medium);
    --amplify-components-expander-border-radius: var(--amplify-radii-medium);
    --amplify-components-divider-opacity: var(--amplify-opacities-60);
    --amplify-components-divider-large-border-width: var(--amplify-border-widths-large);
    --amplify-components-divider-small-border-width: var(--amplify-border-widths-small);
    --amplify-components-divider-label-font-size: var(--amplify-font-sizes-small);
    --amplify-components-divider-label-padding-inline: var(--amplify-space-medium);
    --amplify-components-divider-border-width: var(--amplify-border-widths-medium);
    --amplify-components-countrycodeselect-height: var(--amplify-space-relative-full);
    --amplify-components-copy-tool-tip-font-size: var(--amplify-font-sizes-xxs);
    --amplify-components-copy-tool-tip-color: var(--amplify-colors-teal-100);
    --amplify-components-copy-tool-tip-bottom: var(--amplify-space-large);
    --amplify-components-copy-gap: var(--amplify-space-relative-medium);
    --amplify-components-copy-font-size: var(--amplify-font-sizes-xs);
    --amplify-components-checkbox-icon-indeterminate-opacity: var(--amplify-opacities-100);
    --amplify-components-checkbox-icon-checked-opacity: var(--amplify-opacities-100);
    --amplify-components-checkbox-icon-transition-duration: var(--amplify-time-short);
    --amplify-components-checkbox-icon-opacity: var(--amplify-opacities-0);
    --amplify-components-checkbox-button-error-focus-border-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-focus-outline-offset: var(--amplify-outline-offsets-medium);
    --amplify-components-checkbox-button-focus-outline-width: var(--amplify-outline-widths-medium);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-transparent);
    --amplify-components-checkbox-button-before-border-width: var(--amplify-border-widths-medium);
    --amplify-components-card-elevated-border-radius: var(--amplify-radii-xs);
    --amplify-components-card-outlined-box-shadow: var(--amplify-components-card-box-shadow);
    --amplify-components-card-outlined-border-width: var(--amplify-border-widths-small);
    --amplify-components-card-outlined-border-radius: var(--amplify-radii-xs);
    --amplify-components-card-padding: var(--amplify-space-medium);
    --amplify-components-card-border-radius: var(--amplify-radii-xs);
    --amplify-components-button-loader-wrapper-gap: var(--amplify-space-xs);
    --amplify-components-button-link-border-width: var(--amplify-space-zero);
    --amplify-components-button-menu-border-width: var(--amplify-space-zero);
    --amplify-components-button-primary-border-width: var(--amplify-border-widths-small);
    --amplify-components-button-border-style: var(--amplify-components-fieldcontrol-border-style);
    --amplify-components-button-font-weight: 700;
    --amplify-components-badge-large-padding-horizontal: var(--amplify-space-medium);
    --amplify-components-badge-large-padding-vertical: var(--amplify-space-small);
    --amplify-components-badge-large-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-badge-small-padding-horizontal: var(--amplify-space-xs);
    --amplify-components-badge-small-padding-vertical: var(--amplify-space-xxs);
    --amplify-components-badge-small-font-size: var(--amplify-font-sizes-xs);
    --amplify-components-badge-border-radius: var(--amplify-radii-xl);
    --amplify-components-badge-padding-horizontal: var(--amplify-space-small);
    --amplify-components-badge-padding-vertical: var(--amplify-space-xs);
    --amplify-components-badge-font-size: var(--amplify-font-sizes-small);
    --amplify-components-badge-font-weight: 600;
    --amplify-components-authenticator-or-container-color: var(--amplify-colors-neutral-80);
    --amplify-components-authenticator-form-padding: var(--amplify-space-xl);
    --amplify-components-authenticator-footer-padding-bottom: var(--amplify-space-medium);
    --amplify-components-authenticator-router-border-width: var(--amplify-border-widths-small);
    --amplify-components-authenticator-modal-left: var(--amplify-space-zero);
    --amplify-components-authenticator-modal-top: var(--amplify-space-zero);
    --amplify-components-authenticator-modal-background-color: var(--amplify-colors-overlay-50);
    --amplify-components-authenticator-modal-height: var(--amplify-space-relative-full);
    --amplify-components-authenticator-modal-width: var(--amplify-space-relative-full);
    --amplify-components-alert-heading-font-weight: 700;
    --amplify-components-alert-heading-font-size: var(--amplify-font-sizes-medium);
    --amplify-components-alert-icon-size: var(--amplify-font-sizes-xl);
    --amplify-components-alert-padding-inline: var(--amplify-space-medium);
    --amplify-components-alert-padding-block: var(--amplify-space-small);
    --amplify-colors-border-focus: var(--amplify-colors-brand-primary-100);
    --amplify-colors-border-pressed: var(--amplify-colors-brand-primary-100);
    --amplify-colors-border-disabled: var(--amplify-colors-border-tertiary);
    --amplify-colors-background-disabled: var(--amplify-colors-background-tertiary);
    --amplify-colors-font-active: var(--amplify-colors-brand-primary-100);
    --amplify-colors-font-focus: var(--amplify-colors-brand-primary-100);
    --amplify-colors-font-hover: var(--amplify-colors-brand-primary-90);
    --amplify-colors-font-interactive: var(--amplify-colors-brand-primary-80);
    --amplify-components-togglebutton-link-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-primary-pressed-hover-color: var(--amplify-colors-background-primary);
    --amplify-components-togglebutton-primary-pressed-hover-box-shadow: var(--amplify-colors-brand-primary-60);
    --amplify-components-togglebutton-primary-pressed-hover-background-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-togglebutton-primary-pressed-hover-border-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-togglebutton-primary-pressed-focus-color: var(--amplify-colors-background-primary);
    --amplify-components-togglebutton-primary-pressed-color: var(--amplify-colors-background-primary);
    --amplify-components-togglebutton-primary-pressed-border-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-togglebutton-primary-pressed-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-togglebutton-primary-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-primary-hover-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-primary-focus-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-pressed-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-togglebutton-focus-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-border-color: var(--amplify-colors-border-primary);
    --amplify-components-text-info-color: var(--amplify-colors-font-info);
    --amplify-components-text-success-color: var(--amplify-colors-font-success);
    --amplify-components-text-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-text-error-color: var(--amplify-colors-font-error);
    --amplify-components-text-tertiary-color: var(--amplify-colors-font-tertiary);
    --amplify-components-text-secondary-color: var(--amplify-colors-font-secondary);
    --amplify-components-text-primary-color: var(--amplify-colors-font-primary);
    --amplify-components-text-color: var(--amplify-colors-font-primary);
    --amplify-components-tabs-item-disabled-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-tabs-item-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-tabs-item-color: var(--amplify-colors-font-secondary);
    --amplify-components-tabs-item-border-color: var(--amplify-colors-border-secondary);
    --amplify-components-tabs-border-color: var(--amplify-colors-border-secondary);
    --amplify-components-table-caption-color: var(--amplify-colors-font-primary);
    --amplify-components-table-data-color: var(--amplify-colors-font-primary);
    --amplify-components-table-data-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-table-header-color: var(--amplify-colors-font-primary);
    --amplify-components-table-header-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-table-row-striped-background-color: var(--amplify-colors-background-secondary);
    --amplify-components-table-row-hover-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-switchfield-track-error-background-color: var(--amplify-colors-background-error);
    --amplify-components-switchfield-track-checked-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-switchfield-track-background-color: var(--amplify-colors-background-quaternary);
    --amplify-components-switchfield-thumb-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-switchfield-thumb-background-color: var(--amplify-colors-background-primary);
    --amplify-components-sliderfield-thumb-hover-background-color: var(--amplify-colors-background-primary);
    --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-border-primary);
    --amplify-components-sliderfield-thumb-box-shadow: var(--amplify-shadows-small);
    --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-background-primary);
    --amplify-components-sliderfield-range-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-sliderfield-track-background-color: var(--amplify-colors-background-quaternary);
    --amplify-components-select-option-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-select-option-color: var(--amplify-colors-font-primary);
    --amplify-components-select-option-background-color: var(--amplify-colors-background-primary);
    --amplify-components-searchfield-button-background-color: var(--amplify-colors-background-primary);
    --amplify-components-rating-empty-color: var(--amplify-colors-background-tertiary);
    --amplify-components-rating-filled-color: var(--amplify-colors-brand-secondary-80);
    --amplify-components-radiogroup-radio-border-width: var(--amplify-components-radio-button-border-width);
    --amplify-components-radio-label-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-radio-button-disabled-background-color: var(--amplify-colors-background-primary);
    --amplify-components-radio-button-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-radio-button-checked-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-radio-button-background-color: var(--amplify-colors-background-primary);
    --amplify-components-radio-button-color: var(--amplify-colors-background-primary);
    --amplify-components-radio-button-border-color: var(--amplify-colors-border-primary);
    --amplify-components-placeholder-end-color: var(--amplify-colors-background-tertiary);
    --amplify-components-placeholder-start-color: var(--amplify-colors-background-secondary);
    --amplify-components-pagination-button-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-pagination-button-hover-color: var(--amplify-colors-font-primary);
    --amplify-components-pagination-button-color: var(--amplify-colors-font-primary);
    --amplify-components-pagination-current-color: var(--amplify-colors-font-inverse);
    --amplify-components-menu-box-shadow: var(--amplify-shadows-large);
    --amplify-components-menu-border-color: var(--amplify-colors-border-primary);
    --amplify-components-menu-background-color: var(--amplify-colors-background-primary);
    --amplify-components-loader-text-fill: var(--amplify-colors-font-primary);
    --amplify-components-loader-linear-stroke-filled: var(--amplify-colors-brand-primary-80);
    --amplify-components-loader-stroke-filled: var(--amplify-colors-brand-primary-80);
    --amplify-components-heading-color: var(--amplify-colors-font-primary);
    --amplify-components-fieldmessages-description-color: var(--amplify-colors-font-secondary);
    --amplify-components-fieldmessages-error-color: var(--amplify-colors-font-error);
    --amplify-components-fieldcontrol-error-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-fieldcontrol-quiet-error-focus-box-shadow: 0px 1px 0px var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-quiet-error-border-block-end-color: var(--amplify-colors-border-error);
    --amplify-components-fieldcontrol-large-font-size: var(--amplify-components-field-large-font-size);
    --amplify-components-fieldcontrol-small-font-size: var(--amplify-components-field-small-font-size);
    --amplify-components-fieldcontrol-font-size: var(--amplify-components-field-font-size);
    --amplify-components-fieldcontrol-color: var(--amplify-colors-font-primary);
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-border-primary);
    --amplify-components-field-label-color: var(--amplify-colors-font-secondary);
    --amplify-components-expander-content-text-color: var(--amplify-colors-font-secondary);
    --amplify-components-expander-item-box-shadow: var(--amplify-shadows-small);
    --amplify-components-expander-box-shadow: var(--amplify-shadows-large);
    --amplify-components-expander-background-color: var(--amplify-colors-background-primary);
    --amplify-components-divider-label-background-color: var(--amplify-colors-background-primary);
    --amplify-components-divider-label-color: var(--amplify-colors-font-tertiary);
    --amplify-components-divider-border-color: var(--amplify-colors-border-primary);
    --amplify-components-copy-svg-path-fill: var(--amplify-colors-font-primary);
    --amplify-components-collection-search-button-disabled-background-color: var(--amplify-components-searchfield-button-disabled-background-color);
    --amplify-components-collection-pagination-button-hover-background-color: var(--amplify-components-pagination-button-hover-background-color);
    --amplify-components-collection-pagination-current-background-color: var(--amplify-components-pagination-current-background-color);
    --amplify-components-checkbox-label-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-checkbox-icon-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-checkbox-button-error-focus-box-shadow: 0px 0px 0px 2px var(--amplify-colors-border-error);
    --amplify-components-checkbox-button-error-border-color: var(--amplify-colors-border-error);
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-border-primary);
    --amplify-components-checkbox-button-color: var(--amplify-colors-font-inverse);
    --amplify-components-card-elevated-box-shadow: var(--amplify-shadows-medium);
    --amplify-components-card-outlined-border-color: var(--amplify-colors-border-primary);
    --amplify-components-card-background-color: var(--amplify-colors-background-primary);
    --amplify-components-button-large-padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
    --amplify-components-button-large-padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    --amplify-components-button-large-padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    --amplify-components-button-large-padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    --amplify-components-button-small-padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
    --amplify-components-button-small-padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    --amplify-components-button-small-padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    --amplify-components-button-small-padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    --amplify-components-button-link-loading-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-link-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-link-active-background-color: var(--amplify-colors-brand-primary-20);
    --amplify-components-button-link-focus-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-link-hover-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-menu-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-menu-active-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-menu-active-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-menu-focus-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-menu-focus-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-menu-hover-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-menu-hover-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-active-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-active-background-color: var(--amplify-colors-brand-primary-100);
    --amplify-components-button-primary-focus-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-focus-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-primary-hover-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-hover-background-color: var(--amplify-colors-brand-primary-90);
    --amplify-components-button-primary-loading-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-primary-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-primary-color: var(--amplify-colors-font-inverse);
    --amplify-components-button-primary-background-color: var(--amplify-colors-brand-primary-80);
    --amplify-components-button-disabled-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-button-disabled-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-loading-border-color: var(--amplify-colors-border-tertiary);
    --amplify-components-button-loading-color: var(--amplify-colors-font-disabled);
    --amplify-components-button-active-border-color: var(--amplify-colors-brand-primary-100);
    --amplify-components-button-active-background-color: var(--amplify-colors-brand-primary-20);
    --amplify-components-button-focus-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-hover-border-color: var(--amplify-colors-brand-primary-60);
    --amplify-components-button-hover-background-color: var(--amplify-colors-brand-primary-10);
    --amplify-components-button-color: var(--amplify-colors-font-primary);
    --amplify-components-button-border-radius: var(--amplify-components-fieldcontrol-border-radius);
    --amplify-components-button-border-width: var(--amplify-components-fieldcontrol-border-width);
    --amplify-components-button-padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    --amplify-components-button-padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    --amplify-components-button-padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    --amplify-components-button-padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    --amplify-components-button-line-height: var(--amplify-components-fieldcontrol-line-height);
    --amplify-components-button-transition-duration: var(--amplify-components-fieldcontrol-transition-duration);
    --amplify-components-badge-error-background-color: var(--amplify-colors-background-error);
    --amplify-components-badge-error-color: var(--amplify-colors-font-error);
    --amplify-components-badge-success-background-color: var(--amplify-colors-background-success);
    --amplify-components-badge-success-color: var(--amplify-colors-font-success);
    --amplify-components-badge-warning-background-color: var(--amplify-colors-background-warning);
    --amplify-components-badge-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-badge-info-background-color: var(--amplify-colors-background-info);
    --amplify-components-badge-info-color: var(--amplify-colors-font-info);
    --amplify-components-badge-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-badge-color: var(--amplify-colors-font-primary);
    --amplify-components-authenticator-or-container-or-line-background-color: var(--amplify-colors-background-primary);
    --amplify-components-authenticator-state-inactive-background-color: var(--amplify-colors-background-secondary);
    --amplify-components-authenticator-router-box-shadow: var(--amplify-shadows-medium);
    --amplify-components-authenticator-router-background-color: var(--amplify-colors-background-primary);
    --amplify-components-authenticator-router-border-color: var(--amplify-colors-border-primary);
    --amplify-components-alert-success-background-color: var(--amplify-colors-background-success);
    --amplify-components-alert-success-color: var(--amplify-colors-font-success);
    --amplify-components-alert-warning-background-color: var(--amplify-colors-background-warning);
    --amplify-components-alert-warning-color: var(--amplify-colors-font-warning);
    --amplify-components-alert-error-background-color: var(--amplify-colors-background-error);
    --amplify-components-alert-error-color: var(--amplify-colors-font-error);
    --amplify-components-alert-info-background-color: var(--amplify-colors-background-info);
    --amplify-components-alert-info-color: var(--amplify-colors-font-info);
    --amplify-components-alert-background-color: var(--amplify-colors-background-tertiary);
    --amplify-components-alert-color: var(--amplify-colors-font-primary);
    --amplify-components-togglebutton-primary-pressed-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-primary-pressed-focus-background-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-primary-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-togglebutton-primary-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-togglebutton-primary-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-togglebutton-pressed-border-color: var(--amplify-colors-border-pressed);
    --amplify-components-togglebutton-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-togglebutton-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-textfield-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-textfield-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-textfield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-textareafield-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-textareafield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-font-interactive);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-font-interactive);
    --amplify-components-tabs-item-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-tabs-item-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-switchfield-focused-shadow: 0px 0px 0px 2px var(--amplify-colors-border-focus);
    --amplify-components-stepperfield-button-hover-background-color: var(--amplify-components-button-hover-background-color);
    --amplify-components-stepperfield-button-disabled-color: var(--amplify-components-button-disabled-color);
    --amplify-components-stepperfield-button-focus-background-color: var(--amplify-components-button-focus-background-color);
    --amplify-components-stepperfield-button-active-background-color: var(--amplify-components-button-active-background-color);
    --amplify-components-stepperfield-button-color: var(--amplify-components-button-color);
    --amplify-components-stepperfield-input-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-stepperfield-input-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-stepperfield-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-sliderfield-thumb-focus-box-shadow: 0 0 0 2px var(--amplify-colors-border-focus);
    --amplify-components-sliderfield-thumb-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-sliderfield-thumb-hover-border-color: var(--amplify-colors-border-focus);
    --amplify-components-sliderfield-thumb-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-sliderfield-range-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-selectfield-label-color: var(--amplify-components-field-label-color);
    --amplify-components-selectfield-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-selectfield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-selectfield-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-searchfield-button-hover-border-color: var(--amplify-components-button-hover-border-color);
    --amplify-components-searchfield-button-hover-background-color: var(--amplify-components-button-hover-background-color);
    --amplify-components-searchfield-button-focus-background-color: var(--amplify-components-button-focus-background-color);
    --amplify-components-searchfield-button-disabled-color: var(--amplify-components-button-disabled-color);
    --amplify-components-searchfield-button-disabled-border-color: var(--amplify-components-button-disabled-border-color);
    --amplify-components-searchfield-button-active-border-color: var(--amplify-components-button-active-border-color);
    --amplify-components-searchfield-button-active-background-color: var(--amplify-components-button-active-background-color);
    --amplify-components-searchfield-button-color: var(--amplify-components-button-color);
    --amplify-components-searchfield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-radiogroup-label-color: var(--amplify-components-field-label-color);
    --amplify-components-radiogroup-radio-checked-color: var(--amplify-components-radio-button-checked-color);
    --amplify-components-radiogroup-radio-background-color: var(--amplify-components-radio-button-background-color);
    --amplify-components-radiogroup-radio-border-color: var(--amplify-components-radio-button-border-color);
    --amplify-components-radio-label-color: var(--amplify-components-text-color);
    --amplify-components-radio-button-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-radio-button-error-focus-box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow);
    --amplify-components-radio-button-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-radio-button-checked-disabled-color: var(--amplify-colors-background-disabled);
    --amplify-components-phonenumberfield-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-phonenumberfield-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-phonenumberfield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-passwordfield-button-hover-border-color: var(--amplify-components-button-hover-border-color);
    --amplify-components-passwordfield-button-hover-background-color: var(--amplify-components-button-hover-background-color);
    --amplify-components-passwordfield-button-focus-background-color: var(--amplify-components-button-focus-background-color);
    --amplify-components-passwordfield-button-disabled-color: var(--amplify-components-button-disabled-color);
    --amplify-components-passwordfield-button-disabled-border-color: var(--amplify-components-button-disabled-border-color);
    --amplify-components-passwordfield-button-active-border-color: var(--amplify-components-button-active-border-color);
    --amplify-components-passwordfield-button-active-background-color: var(--amplify-components-button-active-background-color);
    --amplify-components-passwordfield-button-color: var(--amplify-components-button-color);
    --amplify-components-passwordfield-color: var(--amplify-components-fieldcontrol-color);
    --amplify-components-link-visited-color: var(--amplify-colors-font-interactive);
    --amplify-components-link-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-link-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-link-color: var(--amplify-colors-font-interactive);
    --amplify-components-link-active-color: var(--amplify-colors-font-active);
    --amplify-components-fieldcontrol-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-fieldcontrol-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-quiet-focus-box-shadow: 0px 1px 0px var(--amplify-colors-border-focus);
    --amplify-components-fieldcontrol-quiet-focus-border-block-end-color: var(--amplify-colors-border-focus);
    --amplify-components-expander-item-focus-box-shadow: 0 0 0 2px var(--amplify-colors-border-focus);
    --amplify-components-collection-pagination-button-disabled-color: var(--amplify-components-pagination-button-disabled-color);
    --amplify-components-collection-pagination-button-hover-color: var(--amplify-components-pagination-button-hover-color);
    --amplify-components-collection-pagination-button-color: var(--amplify-components-pagination-button-color);
    --amplify-components-collection-pagination-current-color: var(--amplify-components-pagination-current-color);
    --amplify-components-checkbox-icon-indeterminate-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-checkbox-icon-checked-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-checkbox-button-disabled-border-color: var(--amplify-colors-border-disabled);
    --amplify-components-checkbox-button-focus-box-shadow: 0px 0px 0px 2px var(--amplify-colors-border-focus);
    --amplify-components-card-elevated-background-color: var(--amplify-components-card-background-color);
    --amplify-components-card-outlined-background-color: var(--amplify-components-card-background-color);
    --amplify-components-button-large-font-size: var(--amplify-components-fieldcontrol-large-font-size);
    --amplify-components-button-small-font-size: var(--amplify-components-fieldcontrol-small-font-size);
    --amplify-components-button-link-active-color: var(--amplify-colors-font-active);
    --amplify-components-button-link-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-button-link-hover-color: var(--amplify-colors-font-hover);
    --amplify-components-button-link-color: var(--amplify-colors-font-interactive);
    --amplify-components-button-primary-loading-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-button-primary-disabled-background-color: var(--amplify-colors-background-disabled);
    --amplify-components-button-active-color: var(--amplify-colors-font-active);
    --amplify-components-button-focus-border-color: var(--amplify-colors-border-focus);
    --amplify-components-button-focus-color: var(--amplify-colors-font-focus);
    --amplify-components-button-hover-color: var(--amplify-colors-font-focus);
    --amplify-components-button-border-color: var(--amplify-components-fieldcontrol-border-color);
    --amplify-components-button-font-size: var(--amplify-components-fieldcontrol-font-size);
    --amplify-components-togglebutton-primary-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-textfield-focus-border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    --amplify-components-textareafield-focus-border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    --amplify-components-stepperfield-button-hover-color: var(--amplify-components-button-hover-color);
    --amplify-components-stepperfield-button-disabled-background-color: var(--amplify-components-fieldcontrol-disabled-background-color);
    --amplify-components-stepperfield-button-focus-color: var(--amplify-components-button-focus-color);
    --amplify-components-stepperfield-button-active-color: var(--amplify-components-button-active-color);
    --amplify-components-selectfield-focus-border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    --amplify-components-searchfield-button-hover-color: var(--amplify-components-button-hover-color);
    --amplify-components-searchfield-button-focus-color: var(--amplify-components-button-focus-color);
    --amplify-components-searchfield-button-focus-border-color: var(--amplify-components-button-focus-border-color);
    --amplify-components-searchfield-button-active-color: var(--amplify-components-button-active-color);
    --amplify-components-radiogroup-radio-label-color: var(--amplify-components-radio-label-color);
    --amplify-components-radio-button-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-phonenumberfield-focus-border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    --amplify-components-passwordfield-button-hover-color: var(--amplify-components-button-hover-color);
    --amplify-components-passwordfield-button-focus-color: var(--amplify-components-button-focus-color);
    --amplify-components-passwordfield-button-focus-border-color: var(--amplify-components-button-focus-border-color);
    --amplify-components-passwordfield-button-active-color: var(--amplify-components-button-active-color);
    --amplify-components-collection-search-button-hover-border-color: var(--amplify-components-searchfield-button-hover-border-color);
    --amplify-components-collection-search-button-hover-background-color: var(--amplify-components-searchfield-button-hover-background-color);
    --amplify-components-collection-search-button-focus-background-color: var(--amplify-components-searchfield-button-focus-background-color);
    --amplify-components-collection-search-button-disabled-color: var(--amplify-components-searchfield-button-disabled-color);
    --amplify-components-collection-search-button-disabled-border-color: var(--amplify-components-searchfield-button-disabled-border-color);
    --amplify-components-collection-search-button-active-border-color: var(--amplify-components-searchfield-button-active-border-color);
    --amplify-components-collection-search-button-active-background-color: var(--amplify-components-searchfield-button-active-background-color);
    --amplify-components-collection-search-button-color: var(--amplify-components-searchfield-button-color);
    --amplify-components-collection-search-input-color: var(--amplify-components-searchfield-color);
    --amplify-components-button-link-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-button-primary-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-button-focus-box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
    --amplify-components-collection-search-button-hover-color: var(--amplify-components-searchfield-button-hover-color);
    --amplify-components-collection-search-button-focus-color: var(--amplify-components-searchfield-button-focus-color);
    --amplify-components-collection-search-button-focus-border-color: var(--amplify-components-searchfield-button-focus-border-color);
    --amplify-components-collection-search-button-active-color: var(--amplify-components-searchfield-button-active-color);
  }
  
  html,
  [data-amplify-theme] {
    font-family: var(--amplify-fonts-default-static);
  }
  
  @supports (font-variation-settings: normal) {
    html,
  [data-amplify-theme] {
      font-family: var(--amplify-fonts-default-variable);
    }
  }
  html:focus-within {
    scroll-behavior: smooth;
  }
  
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: var(--amplify-line-heights-medium);
  }
  
  * {
    box-sizing: border-box;
  }
  
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }
  }
  [class*=amplify] {
    all: unset; /* protect against external styles */
    box-sizing: border-box; /* set box-sizing after unset above */
  }
  
  .amplify-flex {
    align-content: var(--amplify-components-flex-align-content);
    align-items: var(--amplify-components-flex-align-items);
    display: flex;
    flex-wrap: var(--amplify-components-flex-flex-wrap);
    gap: var(--amplify-components-flex-gap);
    justify-content: var(--amplify-components-flex-justify-content);
  }
  
  .amplify-grid {
    display: grid;
  }
  
  .amplify-scrollview {
    display: block;
    overflow: auto;
  }
  .amplify-scrollview--horizontal {
    overflow-x: scroll;
    overflow-y: initial;
  }
  .amplify-scrollview--vertical {
    overflow-x: initial;
    overflow-y: scroll;
  }
  
  .amplify-text {
    display: block;
    color: var(--amplify-components-text-color);
  }
  b.amplify-text,
  em.amplify-text,
  i.amplify-text,
  span.amplify-text,
  strong.amplify-text {
    display: inline;
  }
  
  .amplify-text--truncated {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .amplify-text--primary {
    color: var(--amplify-components-text-primary-color);
  }
  .amplify-text--secondary {
    color: var(--amplify-components-text-secondary-color);
  }
  .amplify-text--tertiary {
    color: var(--amplify-components-text-tertiary-color);
  }
  .amplify-text--error {
    color: var(--amplify-components-text-error-color);
  }
  .amplify-text--info {
    color: var(--amplify-components-text-info-color);
  }
  .amplify-text--success {
    color: var(--amplify-components-text-success-color);
  }
  .amplify-text--warning {
    color: var(--amplify-components-text-warning-color);
  }
  
  .amplify-badge {
    background-color: var(--amplify-components-badge-background-color);
    border-radius: var(--amplify-components-badge-border-radius);
    color: var(--amplify-components-badge-color);
    display: inline-flex;
    font-size: var(--amplify-components-badge-font-size);
    font-weight: var(--amplify-components-badge-font-weight);
    line-height: var(--amplify-components-badge-line-height);
    padding: var(--amplify-components-badge-padding-vertical) var(--amplify-components-badge-padding-horizontal);
    text-align: var(--amplify-components-badge-text-align);
  }
  .amplify-badge--info {
    color: var(--amplify-components-badge-info-color);
    background-color: var(--amplify-components-badge-info-background-color);
  }
  .amplify-badge--error {
    color: var(--amplify-components-badge-error-color);
    background-color: var(--amplify-components-badge-error-background-color);
  }
  .amplify-badge--warning {
    color: var(--amplify-components-badge-warning-color);
    background-color: var(--amplify-components-badge-warning-background-color);
  }
  .amplify-badge--success {
    color: var(--amplify-components-badge-success-color);
    background-color: var(--amplify-components-badge-success-background-color);
  }
  .amplify-badge--small {
    font-size: var(--amplify-components-badge-small-font-size);
    padding: var(--amplify-components-badge-small-padding-vertical) var(--amplify-components-badge-small-padding-horizontal);
  }
  .amplify-badge--large {
    font-size: var(--amplify-components-badge-large-font-size);
    padding: var(--amplify-components-badge-large-padding-vertical) var(--amplify-components-badge-large-padding-horizontal);
  }
  
  /*
   * Button base styles
   */
  .amplify-button {
    align-items: center;
    border-color: var(--amplify-components-button-border-color);
    border-radius: var(--amplify-components-button-border-radius);
    border-style: var(--amplify-components-button-border-style);
    border-width: var(--amplify-components-button-border-width);
    box-sizing: border-box;
    color: var(--amplify-components-button-color);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--amplify-components-button-font-size);
    font-weight: var(--amplify-components-button-font-weight);
    justify-content: center;
    line-height: var(--amplify-components-button-line-height);
    -webkit-padding-before: var(--amplify-components-button-padding-block-start);
            padding-block-start: var(--amplify-components-button-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-padding-block-end);
            padding-block-end: var(--amplify-components-button-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-padding-inline-start);
            padding-inline-start: var(--amplify-components-button-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-padding-inline-end);
            padding-inline-end: var(--amplify-components-button-padding-inline-end);
    transition: all var(--amplify-components-button-transition-duration);
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    --amplify-internal-button-disabled-color: var(
      --amplify-components-button-disabled-color
    );
    --amplify-internal-button-disabled-background-color: var(
      --amplify-components-button-disabled-background-color
    );
    --amplify-internal-button-disabled-border-color: var(
      --amplify-components-button-disabled-border-color
    );
    --amplify-internal-button-disabled-text-decoration: initial;
    --amplify-internal-button-loading-background-color: var(
      --amplify-components-button-loading-background-color
    );
    --amplify-internal-button-loading-border-color: var(
      --amplify-components-button-loading-border-color
    );
    --amplify-internal-button-loading-color: var(
      --amplify-components-button-loading-color
    );
    --amplify-internal-button-loading-text-decoration: initial;
  }
  .amplify-button:hover {
    background-color: var(--amplify-components-button-hover-background-color);
    border-color: var(--amplify-components-button-hover-border-color);
    color: var(--amplify-components-button-hover-color);
  }
  .amplify-button:focus {
    background-color: var(--amplify-components-button-hover-background-color);
    border-color: var(--amplify-components-button-focus-border-color);
    color: var(--amplify-components-button-focus-color);
    box-shadow: var(--amplify-components-button-focus-box-shadow);
  }
  .amplify-button:active {
    background-color: var(--amplify-components-button-active-background-color);
    border-color: var(--amplify-components-button-active-border-color);
    color: var(--amplify-components-button-active-color);
  }
  .amplify-button--fullwidth {
    width: 100%;
  }
  .amplify-button--menu {
    border-width: var(--amplify-components-button-menu-border-width);
    background-color: var(--amplify-components-button-menu-background-color);
    justify-content: var(--amplify-components-button-menu-justify-content);
    --amplify-internal-button-disabled-color: var(
      --amplify-components-button-menu-disabled-color
    );
  }
  .amplify-button--menu:hover {
    color: var(--amplify-components-button-menu-hover-color);
    background-color: var(--amplify-components-button-menu-hover-background-color);
  }
  .amplify-button--menu:focus {
    box-shadow: none;
    color: var(--amplify-components-button-menu-focus-color);
    background-color: var(--amplify-components-button-menu-focus-background-color);
  }
  .amplify-button--menu:active {
    color: var(--amplify-components-button-menu-active-color);
    background-color: var(--amplify-components-button-menu-active-background-color);
  }
  .amplify-button--primary {
    border-width: var(--amplify-components-button-primary-border-width);
    background-color: var(--amplify-components-button-primary-background-color);
    border-color: var(--amplify-components-button-primary-border-color);
    color: var(--amplify-components-button-primary-color);
    --amplify-internal-button-disabled-border-color: var(
      --amplify-components-button-primary-disabled-border-color
    );
    --amplify-internal-button-disabled-background-color: var(
      --amplify-components-button-primary-disabled-background-color
    );
    --amplify-internal-button-disabled-color: var(
      --amplify-components-button-primary-disabled-color
    );
    --amplify-internal-button-loading-background-color: var(
      --amplify-components-button-primary-loading-background-color
    );
    --amplify-internal-button-loading-border-color: var(
      --amplify-components-button-primary-loading-border-color
    );
    --amplify-internal-button-loading-color: var(
      --amplify-components-button-primary-loading-color
    );
  }
  .amplify-button--primary:hover {
    background-color: var(--amplify-components-button-primary-hover-background-color);
    border-color: var(--amplify-components-button-primary-hover-border-color);
    color: var(--amplify-components-button-primary-hover-color);
  }
  .amplify-button--primary:focus {
    background-color: var(--amplify-components-button-primary-focus-background-color);
    border-color: var(--amplify-components-button-primary-focus-border-color);
    color: var(--amplify-components-button-primary-focus-color);
    box-shadow: var(--amplify-components-button-primary-focus-box-shadow);
  }
  .amplify-button--primary:active {
    background-color: var(--amplify-components-button-primary-active-background-color);
    border-color: var(--amplify-components-button-primary-active-border-color);
    color: var(--amplify-components-button-primary-active-color);
  }
  .amplify-button--link {
    border-width: var(--amplify-components-button-link-border-width);
    background-color: var(--amplify-components-button-link-background-color);
    color: var(--amplify-components-button-link-color);
    --amplify-internal-button-disabled-text-decoration: none;
    --amplify-internal-button-disabled-border-color: var(
      --amplify-components-button-link-disabled-border-color
    );
    --amplify-internal-button-disabled-background-color: var(
      --amplify-components-button-link-disabled-background-color
    );
    --amplify-internal-button-disabled-color: var(
      --amplify-components-button-link-disabled-color
    );
    --amplify-internal-button-loading-background-color: var(
      --amplify-components-button-link-loading-background-color
    );
    --amplify-internal-button-loading-border-color: var(
      --amplify-components-button-link-loading-border-color
    );
    --amplify-internal-button-loading-color: var(
      --amplify-components-button-link-loading-color
    );
    --amplify-internal-button-loading-text-decoration: none;
  }
  .amplify-button--link:hover {
    background-color: var(--amplify-components-button-link-hover-background-color);
    border-color: var(--amplify-components-button-link-hover-border-color);
    color: var(--amplify-components-button-link-hover-color);
  }
  .amplify-button--link:focus {
    background-color: var(--amplify-components-button-link-focus-background-color);
    border-color: var(--amplify-components-button-link-focus-border-color);
    color: var(--amplify-components-button-link-focus-color);
    box-shadow: var(--amplify-components-button-link-focus-box-shadow);
  }
  .amplify-button--link:active {
    background-color: var(--amplify-components-button-link-active-background-color);
    border-color: var(--amplify-components-button-link-active-border-color);
    color: var(--amplify-components-button-link-active-color);
  }
  .amplify-button--small {
    font-size: var(--amplify-components-button-small-font-size);
    -webkit-padding-before: var(--amplify-components-button-small-padding-block-start);
            padding-block-start: var(--amplify-components-button-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-small-padding-block-end);
            padding-block-end: var(--amplify-components-button-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-small-padding-inline-start);
            padding-inline-start: var(--amplify-components-button-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-small-padding-inline-end);
            padding-inline-end: var(--amplify-components-button-small-padding-inline-end);
  }
  .amplify-button--large {
    font-size: var(--amplify-components-button-large-font-size);
    -webkit-padding-before: var(--amplify-components-button-large-padding-block-start);
            padding-block-start: var(--amplify-components-button-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-button-large-padding-block-end);
            padding-block-end: var(--amplify-components-button-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-button-large-padding-inline-start);
            padding-inline-start: var(--amplify-components-button-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-button-large-padding-inline-end);
            padding-inline-end: var(--amplify-components-button-large-padding-inline-end);
  }
  .amplify-button--disabled {
    background-color: var(--amplify-internal-button-disabled-background-color);
    border-color: var(--amplify-internal-button-disabled-border-color);
    color: var(--amplify-internal-button-disabled-color);
    -webkit-text-decoration: var(--amplify-internal-button-disabled-text-decoration);
            text-decoration: var(--amplify-internal-button-disabled-text-decoration);
    cursor: not-allowed;
  }
  .amplify-button--disabled:hover {
    background-color: var(--amplify-internal-button-disabled-background-color);
    border-color: var(--amplify-internal-button-disabled-border-color);
    color: var(--amplify-internal-button-disabled-color);
    -webkit-text-decoration: var(--amplify-internal-button-disabled-text-decoration);
            text-decoration: var(--amplify-internal-button-disabled-text-decoration);
  }
  .amplify-button--disabled :focus {
    background-color: var(--amplify-internal-button-disabled-background-color);
    border-color: var(--amplify-internal-button-disabled-border-color);
    color: var(--amplify-internal-button-disabled-color);
    -webkit-text-decoration: var(--amplify-internal-button-disabled-text-decoration);
            text-decoration: var(--amplify-internal-button-disabled-text-decoration);
  }
  .amplify-button--disabled:active {
    background-color: var(--amplify-internal-button-disabled-background-color);
    border-color: var(--amplify-internal-button-disabled-border-color);
    color: var(--amplify-internal-button-disabled-color);
    -webkit-text-decoration: var(--amplify-internal-button-disabled-text-decoration);
            text-decoration: var(--amplify-internal-button-disabled-text-decoration);
  }
  .amplify-button--loading {
    background-color: var(--amplify-internal-button-loading-background-color);
    border-color: var(--amplify-internal-button-loading-border-color);
    color: var(--amplify-components-button-loading-color);
    -webkit-text-decoration: var(--amplify-internal-button-loading-text-decoration);
            text-decoration: var(--amplify-internal-button-loading-text-decoration);
  }
  .amplify-button--loading:hover {
    background-color: var(--amplify-internal-button-loading-background-color);
    border-color: var(--amplify-internal-button-loading-border-color);
    color: var(--amplify-components-button-loading-color);
    -webkit-text-decoration: var(--amplify-internal-button-loading-text-decoration);
            text-decoration: var(--amplify-internal-button-loading-text-decoration);
  }
  .amplify-button--loading:focus {
    background-color: var(--amplify-internal-button-loading-background-color);
    border-color: var(--amplify-internal-button-loading-border-color);
    color: var(--amplify-components-button-loading-color);
    -webkit-text-decoration: var(--amplify-internal-button-loading-text-decoration);
            text-decoration: var(--amplify-internal-button-loading-text-decoration);
  }
  .amplify-button--loading:active {
    background-color: var(--amplify-internal-button-loading-background-color);
    border-color: var(--amplify-internal-button-loading-border-color);
    color: var(--amplify-components-button-loading-color);
    -webkit-text-decoration: var(--amplify-internal-button-loading-text-decoration);
            text-decoration: var(--amplify-internal-button-loading-text-decoration);
  }
  .amplify-button__loader-wrapper {
    align-items: var(--amplify-components-button-loader-wrapper-align-items);
    gap: var(--amplify-components-button-loader-wrapper-gap);
  }
  
  @media (prefers-reduced-motion: reduce) {
    .amplify-button {
      transition: none;
    }
  }
  .amplify-field__description {
    color: var(--amplify-components-fieldmessages-description-color);
    font-style: var(--amplify-components-fieldmessages-description-font-style);
    font-size: var(--amplify-components-fieldmessages-description-font-size);
  }
  
  .amplify-field__error-message {
    color: var(--amplify-components-fieldmessages-error-color);
    font-size: var(--amplify-components-fieldmessages-error-font-size);
  }
  
  .amplify-heading {
    color: var(--amplify-components-heading-color);
    line-height: var(--amplify-components-heading-line-height);
    display: block;
  }
  .amplify-heading--truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .amplify-heading--1 {
    font-size: var(--amplify-components-heading-1-font-size);
    font-weight: var(--amplify-components-heading-1-font-weight);
  }
  
  .amplify-heading--2 {
    font-size: var(--amplify-components-heading-2-font-size);
    font-weight: var(--amplify-components-heading-2-font-weight);
  }
  
  .amplify-heading--3 {
    font-size: var(--amplify-components-heading-3-font-size);
    font-weight: var(--amplify-components-heading-3-font-weight);
  }
  
  .amplify-heading--4 {
    font-size: var(--amplify-components-heading-4-font-size);
    font-weight: var(--amplify-components-heading-4-font-weight);
  }
  
  .amplify-heading--5 {
    font-size: var(--amplify-components-heading-5-font-size);
    font-weight: var(--amplify-components-heading-5-font-weight);
  }
  
  .amplify-heading--6 {
    font-size: var(--amplify-components-heading-6-font-size);
    font-weight: var(--amplify-components-heading-6-font-weight);
  }
  
  /*
   * Icon base styles
   */
  .amplify-icon {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    height: var(--amplify-components-icon-height);
    line-height: var(--amplify-components-icon-line-height);
  }
  .amplify-icon > svg {
    height: var(--amplify-components-icon-height);
    width: var(--amplify-components-icon-height);
  }
  
  .amplify-input {
    box-sizing: border-box;
    color: var(--amplify-components-fieldcontrol-color);
    font-size: var(--amplify-components-fieldcontrol-font-size);
    line-height: var(--amplify-components-fieldcontrol-line-height);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    transition: all var(--amplify-components-fieldcontrol-transition-duration);
    width: 100%;
    border-color: var(--amplify-components-fieldcontrol-border-color);
    border-radius: var(--amplify-components-fieldcontrol-border-radius);
    border-style: var(--amplify-components-fieldcontrol-border-style);
    border-width: var(--amplify-components-fieldcontrol-border-width);
    outline-color: var(--amplify-components-fieldcontrol-outline-color);
    outline-style: var(--amplify-components-fieldcontrol-outline-style);
    outline-width: var(--amplify-components-fieldcontrol-outline-width);
    outline-offset: var(--amplify-components-fieldcontrol-outline-offset);
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }
  .amplify-input:focus {
    border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  }
  .amplify-input--small {
    font-size: var(--amplify-components-fieldcontrol-small-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-small-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-small-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
  }
  .amplify-input--large {
    font-size: var(--amplify-components-fieldcontrol-large-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-large-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-large-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
  }
  .amplify-input--error {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
  }
  .amplify-input--error:focus {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow);
  }
  .amplify-input[disabled] {
    color: var(--amplify-components-fieldcontrol-disabled-color);
    cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
    border-color: var(--amplify-components-fieldcontrol-disabled-border-color);
    background-color: var(--amplify-components-fieldcontrol-disabled-background-color);
  }
  .amplify-input--quiet {
    -webkit-border-before: var(--amplify-components-fieldcontrol-quiet-border-block-start);
            border-block-start: var(--amplify-components-fieldcontrol-quiet-border-block-start);
    -webkit-border-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
            border-inline-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
    -webkit-border-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
            border-inline-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
    border-radius: var(--amplify-components-fieldcontrol-quiet-border-radius);
  }
  .amplify-input--quiet:focus {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-focus-border-block-end-color);
    box-shadow: var(--amplify-components-fieldcontrol-quiet-focus-box-shadow);
  }
  .amplify-input--quiet[aria-invalid=true] {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-error-border-block-end-color);
  }
  .amplify-input--quiet[aria-invalid=true]:focus {
    box-shadow: var(--amplify-components-fieldcontrol-quiet-error-focus-box-shadow);
  }
  
  .amplify-textarea {
    box-sizing: border-box;
    color: var(--amplify-components-fieldcontrol-color);
    font-size: var(--amplify-components-fieldcontrol-font-size);
    line-height: var(--amplify-components-fieldcontrol-line-height);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    transition: all var(--amplify-components-fieldcontrol-transition-duration);
    width: 100%;
    border-color: var(--amplify-components-fieldcontrol-border-color);
    border-radius: var(--amplify-components-fieldcontrol-border-radius);
    border-style: var(--amplify-components-fieldcontrol-border-style);
    border-width: var(--amplify-components-fieldcontrol-border-width);
    outline-color: var(--amplify-components-fieldcontrol-outline-color);
    outline-style: var(--amplify-components-fieldcontrol-outline-style);
    outline-width: var(--amplify-components-fieldcontrol-outline-width);
    outline-offset: var(--amplify-components-fieldcontrol-outline-offset);
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
    white-space: pre-wrap;
  }
  .amplify-textarea:focus {
    border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  }
  .amplify-textarea--small {
    font-size: var(--amplify-components-fieldcontrol-small-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-small-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-small-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
  }
  .amplify-textarea--large {
    font-size: var(--amplify-components-fieldcontrol-large-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-large-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-large-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
  }
  .amplify-textarea--error {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
  }
  .amplify-textarea--error:focus {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow);
  }
  .amplify-textarea[disabled] {
    color: var(--amplify-components-fieldcontrol-disabled-color);
    cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
    border-color: var(--amplify-components-fieldcontrol-disabled-border-color);
    background-color: var(--amplify-components-fieldcontrol-disabled-background-color);
  }
  .amplify-textarea--quiet {
    -webkit-border-before: var(--amplify-components-fieldcontrol-quiet-border-block-start);
            border-block-start: var(--amplify-components-fieldcontrol-quiet-border-block-start);
    -webkit-border-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
            border-inline-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
    -webkit-border-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
            border-inline-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
    border-radius: var(--amplify-components-fieldcontrol-quiet-border-radius);
  }
  .amplify-textarea--quiet:focus {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-focus-border-block-end-color);
    box-shadow: var(--amplify-components-fieldcontrol-quiet-focus-box-shadow);
  }
  .amplify-textarea--quiet[aria-invalid=true] {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-error-border-block-end-color);
  }
  .amplify-textarea--quiet[aria-invalid=true]:focus {
    box-shadow: var(--amplify-components-fieldcontrol-quiet-error-focus-box-shadow);
  }
  
  .amplify-image {
    height: var(--amplify-components-image-height);
    max-width: var(--amplify-components-image-max-width);
    -o-object-fit: var(--amplify-components-image-object-fit);
       object-fit: var(--amplify-components-image-object-fit);
    -o-object-position: var(--amplify-components-image-object-position);
       object-position: var(--amplify-components-image-object-position);
  }
  
  .amplify-link {
    color: var(--amplify-components-link-color);
    -webkit-text-decoration: var(--amplify-components-link-text-decoration);
            text-decoration: var(--amplify-components-link-text-decoration);
    cursor: pointer;
  }
  .amplify-link:visited {
    color: var(--amplify-components-link-visited-color);
    -webkit-text-decoration: var(--amplify-components-link-visited-text-decoration);
            text-decoration: var(--amplify-components-link-visited-text-decoration);
  }
  .amplify-link:active {
    color: var(--amplify-components-link-active-color);
    -webkit-text-decoration: var(--amplify-components-link-active-text-decoration);
            text-decoration: var(--amplify-components-link-active-text-decoration);
  }
  .amplify-link:focus {
    color: var(--amplify-components-link-focus-color);
    -webkit-text-decoration: var(--amplify-components-link-focus-text-decoration);
            text-decoration: var(--amplify-components-link-focus-text-decoration);
  }
  .amplify-link:hover {
    color: var(--amplify-components-link-hover-color);
    -webkit-text-decoration: var(--amplify-components-link-hover-text-decoration);
            text-decoration: var(--amplify-components-link-hover-text-decoration);
  }
  
  .amplify-loader {
    width: var(--amplify-components-loader-width);
    height: var(--amplify-components-loader-height);
    font-size: var(--amplify-components-loader-font-size);
    fill: none;
    stroke: var(--amplify-components-loader-stroke-empty);
    --amplify-internal-loader-linear-font-size: var(
      --amplify-components-loader-linear-font-size
    );
    --amplify-internal-loader-linear-stroke-width: var(
      --amplify-components-loader-linear-stroke-width
    );
  }
  .amplify-loader circle:last-of-type {
    transform-origin: center center;
    -webkit-animation-name: amplify-loader-circular;
            animation-name: amplify-loader-circular;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: var(--amplify-components-loader-animation-duration);
            animation-duration: var(--amplify-components-loader-animation-duration);
    stroke: var(--amplify-components-loader-stroke-filled);
    stroke-linecap: var(--amplify-components-loader-stroke-linecap);
  }
  @media (prefers-reduced-motion) {
    .amplify-loader circle:last-of-type {
      -webkit-animation: none;
              animation: none;
      stroke-dasharray: 100% 200%;
      stroke-dashoffset: 50%;
    }
  }
  .amplify-loader line:last-of-type {
    stroke: var(--amplify-components-loader-linear-stroke-filled);
    stroke-dasharray: 50% 200%;
    -webkit-animation-name: amplify-loader-linear;
            animation-name: amplify-loader-linear;
    -webkit-animation-duration: var(--amplify-components-loader-linear-animation-duration);
            animation-duration: var(--amplify-components-loader-linear-animation-duration);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  @media (prefers-reduced-motion) {
    .amplify-loader line:last-of-type {
      -webkit-animation: none;
              animation: none;
      stroke-dashoffset: -50%;
    }
  }
  .amplify-loader--small {
    width: var(--amplify-components-loader-small-width);
    height: var(--amplify-components-loader-small-height);
    font-size: var(--amplify-components-loader-small-font-size);
    --amplify-internal-loader-linear-stroke-width: var(
      --amplify-components-loader-linear-small-stroke-width
    );
    --amplify-internal-loader-linear-font-size: var(
      --amplify-components-loader-linear-small-font-size
    );
  }
  .amplify-loader--large {
    width: var(--amplify-components-loader-large-width);
    height: var(--amplify-components-loader-large-height);
    font-size: var(--amplify-components-loader-large-font-size);
    --amplify-internal-loader-linear-stroke-width: var(
      --amplify-components-loader-linear-large-stroke-width
    );
    --amplify-internal-loader-linear-font-size: var(
      --amplify-components-loader-linear-large-font-size
    );
  }
  .amplify-loader--linear {
    width: var(--amplify-components-loader-linear-width);
    min-width: var(--amplify-components-loader-linear-min-width);
    stroke: var(--amplify-components-loader-linear-stroke-empty);
    stroke-linecap: var(--amplify-components-loader-linear-stroke-linecap);
    stroke-width: var(--amplify-internal-loader-linear-stroke-width);
    font-size: var(--amplify-internal-loader-linear-font-size);
  }
  .amplify-loader--determinate circle:last-of-type {
    -webkit-animation: none;
            animation: none;
    transform: rotate(-90deg);
  }
  .amplify-loader--determinate line:last-of-type {
    -webkit-animation: none;
            animation: none;
    stroke-dashoffset: 0%;
    stroke-dasharray: none;
  }
  .amplify-loader__percentage-text {
    fill: var(--amplify-components-loader-text-fill);
    stroke: none;
  }
  
  @-webkit-keyframes amplify-loader-circular {
    0% {
      stroke-dasharray: 100% 200%;
      transform: rotate(120deg);
    }
    50% {
      stroke-dasharray: 20% 400%;
    }
    100% {
      stroke-dasharray: 100% 200%;
      transform: rotate(480deg);
    }
  }
  
  @keyframes amplify-loader-circular {
    0% {
      stroke-dasharray: 100% 200%;
      transform: rotate(120deg);
    }
    50% {
      stroke-dasharray: 20% 400%;
    }
    100% {
      stroke-dasharray: 100% 200%;
      transform: rotate(480deg);
    }
  }
  @-webkit-keyframes amplify-loader-linear {
    0% {
      stroke-dashoffset: 200%;
    }
    100% {
      stroke-dashoffset: -50%;
    }
  }
  @keyframes amplify-loader-linear {
    0% {
      stroke-dashoffset: 200%;
    }
    100% {
      stroke-dashoffset: -50%;
    }
  }
  .amplify-placeholder {
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-duration: var(--amplify-components-placeholder-transition-duration);
            animation-duration: var(--amplify-components-placeholder-transition-duration);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: amplify-placeholder-loading;
            animation-name: amplify-placeholder-loading;
    border-radius: var(--amplify-components-placeholder-border-radius);
    height: var(--amplify-components-placeholder-default-height);
    width: 100%;
    display: block;
  }
  .amplify-placeholder--small {
    height: var(--amplify-components-placeholder-small-height);
  }
  .amplify-placeholder--large {
    height: var(--amplify-components-placeholder-large-height);
  }
  
  @-webkit-keyframes amplify-placeholder-loading {
    0% {
      background-color: var(--amplify-components-placeholder-start-color);
    }
    100% {
      background-color: var(--amplify-components-placeholder-end-color);
    }
  }
  
  @keyframes amplify-placeholder-loading {
    0% {
      background-color: var(--amplify-components-placeholder-start-color);
    }
    100% {
      background-color: var(--amplify-components-placeholder-end-color);
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .amplify-placeholder {
      -webkit-animation: none;
              animation: none;
      background-color: var(--amplify-components-placeholder-end-color);
    }
  }
  [data-amplify-copy] {
    display: flex;
    font-size: var(--amplify-components-copy-font-size);
    gap: var(--amplify-components-copy-gap);
    justify-content: center;
    align-items: center;
    word-break: break-all;
  }
  
  [data-amplify-copy-svg] {
    cursor: pointer;
    position: relative;
  }
  [data-amplify-copy-svg] path {
    fill: var(--amplify-components-copy-svg-path-fill);
  }
  [data-amplify-copy-svg]:hover [data-amplify-copy-tooltip] {
    visibility: visible;
    font-size: var(--amplify-components-copy-tool-tip-font-size);
  }
  
  [data-amplify-copy-tooltip] {
    visibility: hidden;
    position: absolute;
    bottom: var(--amplify-components-copy-tool-tip-bottom);
    color: var(--amplify-components-copy-tool-tip-color);
  }
  
  .amplify-alert {
    align-items: var(--amplify-components-alert-align-items);
    background-color: var(--amplify-components-alert-background-color);
    justify-content: var(--amplify-components-alert-justify-content);
    padding-block: var(--amplify-components-alert-padding-block);
    padding-inline: var(--amplify-components-alert-padding-inline);
  }
  .amplify-alert--info {
    color: var(--amplify-components-alert-info-color);
    background-color: var(--amplify-components-alert-info-background-color);
  }
  .amplify-alert--error {
    color: var(--amplify-components-alert-error-color);
    background-color: var(--amplify-components-alert-error-background-color);
  }
  .amplify-alert--warning {
    color: var(--amplify-components-alert-warning-color);
    background-color: var(--amplify-components-alert-warning-background-color);
  }
  .amplify-alert--success {
    color: var(--amplify-components-alert-success-color);
    background-color: var(--amplify-components-alert-success-background-color);
  }
  
  .amplify-alert__icon {
    font-size: var(--amplify-components-alert-icon-size);
  }
  
  .amplify-alert__heading {
    display: block;
    font-weight: var(--amplify-components-alert-heading-font-weight);
    font-size: var(--amplify-components-alert-heading-font-size);
  }
  
  .amplify-alert__body {
    color: inherit;
    display: block;
  }
  
  .amplify-alert__dismiss {
    color: inherit;
  }
  
  /* Center by default */
  [data-amplify-authenticator] {
    display: grid;
  }
  [data-amplify-authenticator][data-variation=modal] {
    overflow-y: auto;
    width: var(--amplify-components-authenticator-modal-width);
    height: var(--amplify-components-authenticator-modal-height);
    background-color: var(--amplify-components-authenticator-modal-background-color);
    position: fixed;
    top: var(--amplify-components-authenticator-modal-top);
    left: var(--amplify-components-authenticator-modal-left);
    /* Having a z-index at least "wins" by default */
    z-index: 1;
  }
  [data-amplify-authenticator] [data-amplify-container] {
    place-self: center;
  }
  @media (min-width: 30rem) {
    [data-amplify-authenticator] [data-amplify-container] {
      width: var(--amplify-components-authenticator-container-width-max);
    }
  }
  [data-amplify-authenticator] [data-amplify-router] {
    background-color: var(--amplify-components-authenticator-router-background-color);
    box-shadow: var(--amplify-components-authenticator-router-box-shadow);
    border-color: var(--amplify-components-authenticator-router-border-color);
    border-width: var(--amplify-components-authenticator-router-border-width);
    border-style: var(--amplify-components-authenticator-router-border-style);
  }
  [data-amplify-authenticator] [data-amplify-footer] {
    padding-bottom: var(--amplify-components-authenticator-footer-padding-bottom);
    text-align: center;
  }
  [data-amplify-authenticator] [data-amplify-form] {
    padding: var(--amplify-components-authenticator-form-padding);
  }
  [data-amplify-authenticator] [data-state=inactive] {
    background-color: var(--amplify-components-authenticator-state-inactive-background-color);
  }
  @media (max-width: 26rem) {
    [data-amplify-authenticator] [data-amplify-sign-up-errors] {
      font-size: 0.688rem;
    }
  }
  
  .amplify-authenticator__column {
    display: flex;
    flex-direction: column;
  }
  
  .amplify-authenticator__subtitle {
    margin-bottom: var(--amplify-space-medium);
  }
  
  .amplify-authenticator__heading {
    font-size: var(--amplify-font-sizes-xl);
  }
  
  .amplify-authenticator__federated-text {
    align-self: center;
  }
  
  .amplify-card {
    background-color: var(--amplify-components-card-background-color);
    border-radius: var(--amplify-components-card-border-radius);
    border-width: var(--amplify-components-card-border-width);
    border-style: var(--amplify-components-card-border-style);
    border-color: var(--amplify-components-card-border-color);
    box-shadow: var(--amplify-components-card-box-shadow);
    display: block;
    padding: var(--amplify-components-card-padding);
  }
  .amplify-card--outlined {
    background-color: var(--amplify-components-card-outlined-background-color);
    border-radius: var(--amplify-components-card-outlined-border-radius);
    border-width: var(--amplify-components-card-outlined-border-width);
    border-style: var(--amplify-components-card-outlined-border-style);
    border-color: var(--amplify-components-card-outlined-border-color);
    box-shadow: var(--amplify-components-card-outlined-box-shadow);
  }
  .amplify-card--elevated {
    background-color: var(--amplify-components-card-elevated-background-color);
    border-radius: var(--amplify-components-card-elevated-border-radius);
    border-width: var(--amplify-components-card-elevated-border-width);
    border-style: var(--amplify-components-card-elevated-border-style);
    border-color: var(--amplify-components-card-elevated-border-color);
    box-shadow: var(--amplify-components-card-elevated-box-shadow);
  }
  
  .amplify-collection {
    flex-direction: column;
  }
  .amplify-collection-pagination {
    justify-content: center;
    --amplify-components-pagination-current-color: var(
      --amplify-components-collection-pagination-current-color
    );
    --amplify-components-pagination-current-background-color: var(
      --amplify-components-collection-pagination-current-background-color
    );
    --amplify-components-pagination-button-color: var(
      --amplify-components-collection-pagination-button-color
    );
    --amplify-components-pagination-button-hover-color: var(
      --amplify-components-collection-pagination-button-hover-color
    );
    --amplify-components-pagination-button-hover-background-color: var(
      --amplify-components-collection-pagination-button-hover-background-color
    );
    --amplify-components-pagination-button-disabled-color: var(
      --amplify-components-collection-pagination-button-disabled-color
    );
  }
  .amplify-collection-search {
    flex-direction: row;
    justify-content: center;
    --amplify-components-searchfield-input-color: var(
      --amplify-components-collection-search-input-color
    );
    --amplify-components-searchfield-button-color: var(
      --amplify-components-collection-search-button-color
    );
    --amplify-components-searchfield-button-active-background-color: var(
      --amplify-components-collection-search-button-active-background-color
    );
    --amplify-components-searchfield-button-active-border-color: var(
      --amplify-components-collection-search-button-active-border-color
    );
    --amplify-components-searchfield-button-active-color: var(
      --amplify-components-collection-search-button-active-color
    );
    --amplify-components-searchfield-button-disabled-background-color: var(
      --amplify-components-collection-search-button-disabled-background-color
    );
    --amplify-components-searchfield-button-disabled-border-color: var(
      --amplify-components-collection-search-button-disabled-border-color
    );
    --amplify-components-searchfield-button-disabled-color: var(
      --amplify-components-collection-search-button-disabled-color
    );
    --amplify-components-searchfield-button-focus-background-color: var(
      --amplify-components-collection-search-button-focus-background-color
    );
    --amplify-components-searchfield-button-focus-border-color: var(
      --amplify-components-collection-search-button-focus-border-color
    );
    --amplify-components-searchfield-button-focus-color: var(
      --amplify-components-collection-search-button-focus-color
    );
    --amplify-components-searchfield-button-hover-background-color: var(
      --amplify-components-collection-search-button-hover-background-color
    );
    --amplify-components-searchfield-button-hover-border-color: var(
      --amplify-components-collection-search-button-hover-border-color
    );
    --amplify-components-searchfield-button-hover-color: var(
      --amplify-components-collection-search-button-hover-color
    );
  }
  
  .amplify-checkbox {
    cursor: var(--amplify-components-checkbox-cursor);
    align-items: var(--amplify-components-checkbox-align-items);
    flex-direction: row-reverse;
    gap: inherit;
  }
  .amplify-checkbox--disabled {
    cursor: var(--amplify-components-checkbox-disabled-cursor);
  }
  
  .amplify-checkbox__button {
    position: var(--amplify-components-checkbox-button-position);
    align-items: var(--amplify-components-checkbox-button-align-items);
    justify-content: var(--amplify-components-checkbox-button-justify-content);
    color: var(--amplify-components-checkbox-button-color);
    --amplify-internal-checkbox_button-focused-before-border-color: var(
      --amplify-components-checkbox-button-focus-border-color
    );
    --amplify-internal-checkbox_button-focused-before-box-shadow: var(
      --amplify-components-checkbox-button-focus-box-shadow
    );
  }
  .amplify-checkbox__button::before {
    content: "";
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    width: var(--amplify-components-checkbox-button-before-width);
    height: var(--amplify-components-checkbox-button-before-height);
    border-width: var(--amplify-components-checkbox-button-before-border-width);
    border-radius: var(--amplify-components-checkbox-button-before-border-radius);
    border-style: var(--amplify-components-checkbox-button-before-border-style);
    border-color: var(--amplify-components-checkbox-button-before-border-color);
  }
  .amplify-checkbox__button--focused::before {
    outline-color: var(--amplify-components-checkbox-button-focus-outline-color);
    outline-style: var(--amplify-components-checkbox-button-focus-outline-style);
    outline-width: var(--amplify-components-checkbox-button-focus-outline-width);
    outline-offset: var(--amplify-components-checkbox-button-focus-outline-offset);
    border-color: var(--amplify-internal-checkbox_button-focused-before-border-color);
    box-shadow: var(--amplify-internal-checkbox_button-focused-before-box-shadow);
  }
  .amplify-checkbox__button--error {
    --amplify-internal-checkbox_button-focused-before-border-color: var(
      --amplify-components-checkbox-button-error-focus-border-color
    );
    --amplify-internal-checkbox_button-focused-before-box-shadow: var(
      --amplify-components-checkbox-button-error-focus-box-shadow
    );
  }
  .amplify-checkbox__button--error::before {
    border-color: var(--amplify-components-checkbox-button-error-border-color);
  }
  .amplify-checkbox__button--disabled::before {
    border-color: var(--amplify-components-checkbox-button-disabled-border-color);
  }
  
  .amplify-checkbox__icon {
    background-color: var(--amplify-components-checkbox-icon-background-color);
    opacity: var(--amplify-components-checkbox-icon-opacity);
    transform: var(--amplify-components-checkbox-icon-transform);
    border-radius: var(--amplify-components-checkbox-icon-border-radius);
    transition-property: var(--amplify-components-checkbox-icon-transition-property);
    transition-duration: var(--amplify-components-checkbox-icon-transition-duration);
    transition-timing-function: var(--amplify-components-checkbox-icon-transition-timing-function);
    --amplify-internal-checkbox-icon-disabled-background-color: var(
      --amplify-components-checkbox-icon-background-color
    );
  }
  .amplify-checkbox__icon--checked {
    opacity: var(--amplify-components-checkbox-icon-checked-opacity);
    transform: var(--amplify-components-checkbox-icon-checked-transform);
    --amplify-internal-checkbox-icon-disabled-background-color: var(
      --amplify-components-checkbox-icon-checked-disabled-background-color
    );
  }
  .amplify-checkbox__icon--indeterminate {
    opacity: var(--amplify-components-checkbox-icon-indeterminate-opacity);
    transform: var(--amplify-components-checkbox-icon-indeterminate-transform);
    --amplify-internal-checkbox-icon-disabled-background-color: var(
      --amplify-components-checkbox-icon-indeterminate-disabled-background-color
    );
  }
  .amplify-checkbox__icon--disabled {
    background-color: var(--amplify-internal-checkbox-icon-disabled-background-color);
  }
  
  .amplify-checkbox__label[data-disabled=true] {
    color: var(--amplify-components-checkbox-label-disabled-color);
  }
  
  .amplify-checkboxfield {
    align-content: var(--amplify-components-checkboxfield-align-content);
    align-items: var(--amplify-components-checkboxfield-align-items);
    flex-direction: var(--amplify-components-checkboxfield-flex-direction);
    justify-content: var(--amplify-components-checkboxfield-justify-content);
  }
  
  .amplify-dialcodeselect {
    height: var(--amplify-components-countrycodeselect-height);
  }
  
  .amplify-divider {
    border-color: var(--amplify-components-divider-border-color);
    border-style: var(--amplify-components-divider-border-style);
    border-width: 0;
    opacity: var(--amplify-components-divider-opacity);
    padding: 0;
    display: block;
    --amplify-internal-divider-size: var(
      --amplify-components-divider-border-width
    );
    position: relative;
    text-align: center;
    margin: 20px 0px;
  }
  
  .amplify-divider--small {
    --amplify-internal-divider-size: var(
      --amplify-components-divider-small-border-width
    );
  }
  .amplify-divider--large {
    --amplify-internal-divider-size: var(
      --amplify-components-divider-large-border-width
    );
  }
  .amplify-divider--horizontal {
    width: 100%;
    border-bottom-width: var(--amplify-internal-divider-size);
  }
  .amplify-divider--vertical {
    border-left-width: var(--amplify-internal-divider-size);
  }
  .amplify-divider--label {
    display: flex;
    justify-content: center;
  }
  .amplify-divider--label::after {
    content: attr(data-label);
    position: absolute;
    transform: translateY(-50%);
    font-size: var(--amplify-components-divider-label-font-size);
    padding-inline: var(--amplify-components-divider-label-padding-inline);
    background-color: var(--amplify-components-divider-label-background-color);
    color: var(--amplify-components-divider-label-color);
  }
  .amplify-divider--label[aria-orientation=vertical] {
    flex-direction: column;
  }
  .amplify-divider--label[aria-orientation=vertical]::after {
    top: auto;
    transform: translateX(-50%);
    padding-block: 0;
    padding-inline: 0;
  }
  
  .amplify-expander {
    display: block;
    background-color: var(--amplify-components-expander-background-color);
    border-radius: var(--amplify-components-expander-border-radius);
    box-shadow: var(--amplify-components-expander-box-shadow);
    width: var(--amplify-components-expander-width);
  }
  
  .amplify-expander__item {
    display: block;
    overflow: hidden;
    box-shadow: var(--amplify-components-expander-item-box-shadow);
  }
  .amplify-expander__item:first-of-type {
    border-start-start-radius: var(--amplify-components-expander-item-border-start-start-radius);
    border-start-end-radius: var(--amplify-components-expander-item-border-start-end-radius);
  }
  @supports not (border-start-start-radius: var(--amplify-components-expander-item-border-start-start-radius)) {
    .amplify-expander__item:first-of-type {
      border-top-left-radius: var(--amplify-components-expander-item-border-top-left-radius);
      border-top-right-radius: var(--amplify-components-expander-item-border-top-right-radius);
    }
  }
  .amplify-expander__item:last-of-type {
    box-shadow: none;
    border-end-start-radius: var(--amplify-components-expander-item-border-end-start-radius);
    border-end-end-radius: var(--amplify-components-expander-item-border-end-end-radius);
  }
  @supports not (border-end-start-radius: var(--amplify-components-expander-item-border-end-start-radius)) {
    .amplify-expander__item:last-of-type {
      border-bottom-left-radius: var(--amplify-components-expander-item-border-bottom-left-radius);
      border-bottom-right-radius: var(--amplify-components-expander-item-border-bottom-right-radius);
    }
  }
  .amplify-expander__item:focus-within {
    box-shadow: var(--amplify-components-expander-item-focus-box-shadow);
  }
  
  .amplify-expander__header {
    display: flex;
    box-shadow: var(--amplify-components-expander-header-box-shadow);
  }
  
  .amplify-expander__trigger {
    flex: 1;
    display: flex;
    min-height: var(--amplify-components-expander-trigger-min-height);
    -webkit-padding-start: var(--amplify-components-expander-trigger-padding-inline-start);
            padding-inline-start: var(--amplify-components-expander-trigger-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-expander-trigger-padding-inline-end);
            padding-inline-end: var(--amplify-components-expander-trigger-padding-inline-end);
    align-items: var(--amplify-components-expander-trigger-align-items);
    justify-content: var(--amplify-components-expander-trigger-justify-content);
  }
  .amplify-expander__trigger:hover {
    background-color: var(--amplify-components-expander-trigger-hover-background-color);
  }
  
  .amplify-expander__content {
    display: block;
    -webkit-padding-start: var(--amplify-components-expander-content-padding-inline-start);
            padding-inline-start: var(--amplify-components-expander-content-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-expander-content-padding-inline-end);
            padding-inline-end: var(--amplify-components-expander-content-padding-inline-end);
  }
  .amplify-expander__content[data-state=open] {
    -webkit-animation-name: amplify-expander-slide-down;
            animation-name: amplify-expander-slide-down;
    -webkit-animation-duration: var(--amplify-components-expander-content-open-animation-duration);
            animation-duration: var(--amplify-components-expander-content-open-animation-duration);
    -webkit-animation-timing-function: var(--amplify-components-expander-content-open-animation-timing-function);
            animation-timing-function: var(--amplify-components-expander-content-open-animation-timing-function);
  }
  .amplify-expander__content[data-state=closed] {
    -webkit-animation-name: amplify-expander-slide-up;
            animation-name: amplify-expander-slide-up;
    -webkit-animation-duration: var(--amplify-components-expander-content-closed-animation-duration);
            animation-duration: var(--amplify-components-expander-content-closed-animation-duration);
    -webkit-animation-timing-function: var(--amplify-components-expander-content-closed-animation-timing-function);
            animation-timing-function: var(--amplify-components-expander-content-closed-animation-timing-function);
  }
  
  .amplify-expander__content__text {
    display: block;
    color: var(--amplify-components-expander-content-text-color);
    -webkit-padding-before: var(--amplify-components-expander-content-text-padding-block-start);
            padding-block-start: var(--amplify-components-expander-content-text-padding-block-start);
    -webkit-padding-after: var(--amplify-components-expander-content-text-padding-block-end);
            padding-block-end: var(--amplify-components-expander-content-text-padding-block-end);
  }
  
  .amplify-expander__icon {
    transition-property: transform;
    transition-duration: var(--amplify-components-expander-icon-transition-duration);
    transition-timing-function: var(--amplify-components-expander-icon-transition-timing-function);
  }
  [data-state=open] .amplify-expander__icon {
    transform: rotate(180deg);
  }
  
  @-webkit-keyframes amplify-expander-slide-down {
    from {
      height: 0;
    }
    to {
      height: auto;
    }
  }
  
  @keyframes amplify-expander-slide-down {
    from {
      height: 0;
    }
    to {
      height: auto;
    }
  }
  @-webkit-keyframes amplify-expander-slide-up {
    from {
      height: auto;
    }
    to {
      height: 0;
    }
  }
  @keyframes amplify-expander-slide-up {
    from {
      height: auto;
    }
    to {
      height: 0;
    }
  }
  .amplify-field {
    font-size: var(--amplify-components-field-font-size);
    gap: var(--amplify-components-field-gap);
  }
  .amplify-field--small {
    font-size: var(--amplify-components-field-small-font-size);
    gap: var(--amplify-components-field-small-gap);
  }
  .amplify-field--large {
    font-size: var(--amplify-components-field-large-font-size);
    gap: var(--amplify-components-field-large-gap);
  }
  
  .amplify-label {
    color: var(--amplify-components-field-label-color);
  }
  
  .amplify-field-group__outer-end .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group :not(:first-child) .amplify-input {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
  @supports not (border-start-start-radius: 0) {
    .amplify-field-group__outer-end .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group :not(:first-child) .amplify-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  
  .amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select, .amplify-field-group__outer-end--quiet .amplify-field-group__control, .amplify-field-group__outer-end .amplify-field-group__control:not(:last-child), .amplify-field-group__outer-start .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-start .amplify-field-group__control, .amplify-field-group :not(:last-child) .amplify-input {
    border-end-end-radius: 0;
    border-start-end-radius: 0;
  }
  @supports not (border-end-end-radius: 0) {
    .amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select, .amplify-field-group__outer-end--quiet .amplify-field-group__control, .amplify-field-group__outer-end .amplify-field-group__control:not(:last-child), .amplify-field-group__outer-start .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-start .amplify-field-group__control, .amplify-field-group :not(:last-child) .amplify-input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  
  .amplify-field-group {
    gap: var(--amplify-components-fieldgroup-gap);
    align-self: stretch;
  }
  .amplify-field-group--horizontal {
    flex-direction: row;
  }
  .amplify-field-group--vertical {
    flex-direction: column;
    align-items: var(--amplify-components-fieldgroup-vertical-align-items);
  }
  .amplify-field-group__outer-start,
  .amplify-field-group__outer-end {
    display: flex;
    align-items: var(--amplify-components-fieldgroup-outer-align-items);
  }
  .amplify-field-group__outer-start .amplify-field-group__control,
  .amplify-field-group__outer-end .amplify-field-group__control {
    height: 100%;
  }
  
  /**
   * Outer field group components
   */
  .amplify-field-group__outer-start .amplify-field-group__control:not(:focus) {
    border-inline-end-color: transparent;
  }
  .amplify-field-group__outer-start .amplify-field-group__control:focus {
    z-index: 1;
  }
  .amplify-field-group__outer-start--quiet .amplify-field-group__control:not(:focus) {
    border-block-start-color: transparent;
    border-inline-start-color: transparent;
  }
  .amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
    border-inline-start-color: transparent;
  }
  .amplify-field-group__outer-end .amplify-field-group__control:focus {
    z-index: 1;
  }
  .amplify-field-group__outer-end--quiet .amplify-field-group__control:not(:focus) {
    border-block-start-color: transparent;
    border-inline-end-color: transparent;
  }
  /**
   * Inner field group components
   */
  .amplify-field-group__field-wrapper {
    position: relative;
    width: 100%;
  }
  .amplify-field-group__field-wrapper--vertical {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .amplify-field-group__inner-end,
  .amplify-field-group__inner-start {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
  .amplify-field-group__inner-end .amplify-button,
  .amplify-field-group__inner-start .amplify-button {
    pointer-events: all;
    height: 100%;
  }
  
  .amplify-field-group__inner-end {
    right: 0;
    left: auto;
  }
  
  .amplify-field-group__inner-start {
    right: auto;
    left: 0;
  }
  
  html[dir=rtl] .amplify-field-group__inner-end {
    right: auto;
    left: 0;
  }
  html[dir=rtl] .amplify-field-group__inner-start {
    left: auto;
    right: 0;
  }
  
  .amplify-field-group--has-inner-end .amplify-input {
    -webkit-padding-end: calc(var(--amplify-components-fieldcontrol-padding-inline-end) * 3);
            padding-inline-end: calc(var(--amplify-components-fieldcontrol-padding-inline-end) * 3);
  }
  
  .amplify-field-group--has-inner-start .amplify-input {
    -webkit-padding-start: calc(var(--amplify-components-fieldcontrol-padding-inline-start) * 3);
            padding-inline-start: calc(var(--amplify-components-fieldcontrol-padding-inline-start) * 3);
  }
  
  /**
   * Inner icon (non-button) component styling requires additional styling
   */
  .amplify-field-group__icon:not(.amplify-field-group__icon-button) {
    display: flex;
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-start);
            padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-start);
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  [data-radix-popper-content-wrapper] {
    z-index: 999999;
  }
  
  .amplify-menu-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .amplify-menu-content {
    background-color: var(--amplify-components-menu-background-color);
    border-radius: var(--amplify-components-menu-border-radius);
    box-shadow: var(--amplify-components-menu-box-shadow);
    flex-direction: var(--amplify-components-menu-flex-direction);
    gap: var(--amplify-components-menu-gap);
    min-width: var(--amplify-components-menu-min-width);
    max-width: var(--amplify-components-menu-max-width);
    border-color: var(--amplify-components-menu-border-color);
    border-width: var(--amplify-components-menu-border-width);
    border-style: var(--amplify-components-menu-border-style);
  }
  
  .amplify-menu-content__item {
    min-height: var(--amplify-components-menu-item-min-height);
    -webkit-padding-start: var(--amplify-components-menu-item-padding-inline-start);
            padding-inline-start: var(--amplify-components-menu-item-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-menu-item-padding-inline-end);
            padding-inline-end: var(--amplify-components-menu-item-padding-inline-end);
  }
  
  .amplify-menu-content__item:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  
  .amplify-menu-content__item:first-child {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
  }
  @supports not (border-end-end-radius: 0) {
    .amplify-menu-content__item:first-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  
  .amplify-menu-content__item:last-child {
    border-start-end-radius: 0;
    border-start-start-radius: 0;
  }
  @supports not (border-end-end-radius: 0) {
    .amplify-menu-content__item:last-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
  
  .amplify-pagination__item-current, .amplify-pagination__item-button {
    height: var(--amplify-components-pagination-item-shared-height);
    min-width: var(--amplify-components-pagination-item-shared-min-width);
    border-radius: var(--amplify-components-pagination-item-shared-border-radius);
  }
  
  .amplify-pagination {
    list-style-type: none;
  }
  .amplify-pagination li {
    margin-left: var(--amplify-components-pagination-item-container-margin-left);
    margin-right: var(--amplify-components-pagination-item-container-margin-right);
  }
  .amplify-pagination__item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: initial;
    color: var(--amplify-components-pagination-button-color);
    -webkit-padding-start: var(--amplify-components-pagination-button-padding-inline-start);
            padding-inline-start: var(--amplify-components-pagination-button-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-pagination-button-padding-inline-end);
            padding-inline-end: var(--amplify-components-pagination-button-padding-inline-end);
    transition-property: var(--amplify-components-pagination-button-transition-property);
    transition-duration: var(--amplify-components-pagination-button-transition-duration);
  }
  .amplify-pagination__item-button:hover {
    text-decoration: none;
    color: var(--amplify-components-pagination-button-hover-color);
    background-color: var(--amplify-components-pagination-button-hover-background-color);
  }
  .amplify-pagination__item-button--disabled {
    color: var(--amplify-components-pagination-button-disabled-color);
    pointer-events: none;
  }
  .amplify-pagination__item-current {
    align-items: var(--amplify-components-pagination-current-align-items);
    justify-content: var(--amplify-components-pagination-current-justify-content);
    color: var(--amplify-components-pagination-current-color);
    font-size: var(--amplify-components-pagination-current-font-size);
    background-color: var(--amplify-components-pagination-current-background-color);
  }
  .amplify-pagination__item-ellipsis {
    align-items: var(--amplify-components-pagination-ellipsis-align-items);
    justify-content: var(--amplify-components-pagination-ellipsis-justify-content);
    -webkit-padding-start: var(--amplify-components-pagination-ellipsis-padding-inline-start);
            padding-inline-start: var(--amplify-components-pagination-ellipsis-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-pagination-ellipsis-padding-inline-end);
            padding-inline-end: var(--amplify-components-pagination-ellipsis-padding-inline-end);
  }
  
  .amplify-passwordfield {
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-passwordfield-color
    );
    --amplify-components-button-color: var(
      --amplify-components-passwordfield-button-color
    );
    --amplify-components-button-active-background-color: var(
      --amplify-components-passwordfield-button-active-background-color
    );
    --amplify-components-button-active-border-color: var(
      --amplify-components-passwordfield-button-active-border-color
    );
    --amplify-components-button-active-color: var(
      --amplify-components-passwordfield-button-active-color
    );
    --amplify-components-button-disabled-background-color: var(
      --amplify-components-passwordfield-button-disabled-background-color
    );
    --amplify-components-button-disabled-border-color: var(
      --amplify-components-passwordfield-button-disabled-border-color
    );
    --amplify-components-button-disabled-color: var(
      --amplify-components-passwordfield-button-disabled-color
    );
    --amplify-components-button-focus-background-color: var(
      --amplify-components-passwordfield-button-focus-background-color
    );
    --amplify-components-button-focus-border-color: var(
      --amplify-components-passwordfield-button-focus-border-color
    );
    --amplify-components-button-focus-color: var(
      --amplify-components-passwordfield-button-focus-color
    );
    --amplify-components-button-hover-background-color: var(
      --amplify-components-passwordfield-button-hover-background-color
    );
    --amplify-components-button-hover-border-color: var(
      --amplify-components-passwordfield-button-hover-border-color
    );
    --amplify-components-button-hover-color: var(
      --amplify-components-passwordfield-button-hover-color
    );
  }
  
  .amplify-phonenumberfield select:not(:focus) {
    border-right: none;
  }
  
  .amplify-phonenumberfield {
    --amplify-components-selectfield-color: var(
      --amplify-components-phonenumberfield-color
    );
    --amplify-components-selectfield-border-color: var(
      --amplify-components-phonenumberfield-border-color
    );
    --amplify-components-selectfield-font-size: var(
      --amplify-components-phonenumberfield-font-size
    );
    --amplify-components-selectfield-focus-border-color: var(
      --amplify-components-phonenumberfield-focus-border-color
    );
    --amplify-components-textfield-color: var(
      --amplify-components-phonenumberfield-color
    );
    --amplify-components-textfield-border-color: var(
      --amplify-components-phonenumberfield-border-color
    );
    --amplify-components-textfield-font-size: var(
      --amplify-components-phonenumberfield-font-size
    );
    --amplify-components-textfield-focus-border-color: var(
      --amplify-components-phonenumberfield-focus-border-color
    );
  }
  
  .amplify-rating {
    display: inline-flex;
    position: relative;
    text-align: left;
    font-size: var(--amplify-components-rating-default-size);
    line-height: var(--amplify-components-rating-default-size);
  }
  .amplify-rating .amplify-icon {
    font-size: unset;
    line-height: unset;
    height: 1em;
  }
  .amplify-rating--small {
    font-size: var(--amplify-components-rating-small-size);
    line-height: var(--amplify-components-rating-small-size);
  }
  .amplify-rating--large {
    font-size: var(--amplify-components-rating-large-size);
    line-height: var(--amplify-components-rating-large-size);
  }
  
  .amplify-rating-filled {
    fill: currentColor;
    display: inline-block;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
  }
  
  .amplify-rating-icon-container {
    position: relative;
    height: 1em;
    width: 1em;
  }
  
  .amplify-rating-label {
    position: absolute;
    overflow: hidden;
    height: 1em;
    width: 1em;
  }
  
  .amplify-rating-icon {
    width: 1em;
    height: 1em;
  }
  
  .amplify-rating-icon-filled {
    color: var(--amplify-components-rating-filled-color);
  }
  
  .amplify-rating-icon-empty {
    color: var(--amplify-components-rating-empty-color);
  }
  
  .amplify-radio {
    align-items: var(--amplify-components-radio-align-items);
    justify-content: var(--amplify-components-radio-justify-content);
    gap: inherit;
    flex-direction: row-reverse;
    --amplify-components-text-color: var(--amplify-components-radio-label-color);
  }
  .amplify-radio--disabled {
    cursor: var(--amplify-components-radio-disabled-cursor);
  }
  
  .amplify-radio__button {
    align-items: var(--amplify-components-radio-button-align-items);
    justify-content: var(--amplify-components-radio-button-justify-content);
    padding: var(--amplify-components-radio-button-padding);
    box-sizing: var(--amplify-components-radio-button-box-sizing);
    border-width: var(--amplify-components-radio-button-border-width);
    border-style: var(--amplify-components-radio-button-border-style);
    border-radius: var(--amplify-components-radio-button-border-radius);
    border-color: var(--amplify-components-radio-button-border-color);
    color: var(--amplify-components-radio-button-color);
    background-color: var(--amplify-components-radio-button-background-color);
    transition-property: var(--amplify-components-radio-button-transition-property);
    transition-duration: var(--amplify-components-radio-button-transition-duration);
    width: var(--amplify-components-radio-button-width);
    height: var(--amplify-components-radio-button-height);
    outline-color: var(--amplify-components-radio-button-outline-color);
    outline-style: var(--amplify-components-radio-button-outline-style);
    outline-width: var(--amplify-components-radio-button-outline-width);
    outline-offset: var(--amplify-components-radio-button-outline-offset);
  }
  .amplify-radio__button::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: var(--amplify-components-radio-button-before-border-radius);
    background-color: currentColor;
    border-radius: 50%;
  }
  .amplify-radio__button--small {
    width: var(--amplify-components-radio-button-small-width);
    height: var(--amplify-components-radio-button-small-height);
  }
  .amplify-radio__button--large {
    width: var(--amplify-components-radio-button-large-width);
    height: var(--amplify-components-radio-button-large-height);
  }
  
  .amplify-radio__input:checked + .amplify-radio__button {
    color: var(--amplify-components-radio-button-checked-color);
  }
  
  .amplify-radio__input:checked:disabled + .amplify-radio__button {
    color: var(--amplify-components-radio-button-checked-disabled-color);
  }
  
  .amplify-radio__input:focus + .amplify-radio__button {
    border-color: var(--amplify-components-radio-button-focus-border-color);
    box-shadow: var(--amplify-components-radio-button-focus-box-shadow);
  }
  
  .amplify-radio__input[aria-invalid=true] + .amplify-radio__button {
    border-color: var(--amplify-components-radio-button-error-border-color);
  }
  
  .amplify-radio__input[aria-invalid=true]:focus + .amplify-radio__button {
    box-shadow: var(--amplify-components-radio-button-error-focus-box-shadow);
  }
  
  .amplify-radio__input:disabled + .amplify-radio__button {
    border-color: var(--amplify-components-radio-button-disabled-border-color);
    background-color: var(--amplify-components-radio-button-disabled-background-color);
  }
  
  .amplify-radio__label[data-disabled=true] {
    color: var(--amplify-components-radio-label-disabled-color);
  }
  
  .amplify-radiogroup {
    gap: inherit;
    flex-direction: inherit;
    align-items: inherit;
  }
  
  .amplify-radiogroupfield {
    flex-direction: column;
    align-items: flex-start;
    --amplify-components-field-label-color: var(
      --amplify-components-radiogroup-label-color
    );
    --amplify-components-radio-button-border-width: var(
      --amplify-components-radiogroup-radio-border-width
    );
    --amplify-components-radio-button-border-color: var(
      --amplify-components-radiogroup-radio-border-color
    );
    --amplify-components-radio-button-background-color: var(
      --amplify-components-radiogroup-radio-background-color
    );
    --amplify-components-radio-label-color: var(
      --amplify-components-radiogroup-radio-label-color
    );
    --amplify-components-radio-button-checked-color: var(
      --amplify-components-radiogroup-radio-checked-color
    );
  }
  
  .amplify-searchfield {
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-searchfield-input-color
    );
  }
  .amplify-searchfield__search {
    color: var(--amplify-components-searchfield-button-color);
    background-color: var(--amplify-components-searchfield-button-background-color);
  }
  .amplify-searchfield__search:active {
    background-color: var(--amplify-components-button-active-background-color);
    border-color: var(--amplify-components-button-active-border-color);
    color: var(--amplify-components-button-active-color);
  }
  .amplify-searchfield__search:disabled {
    background-color: var(--amplify-components-searchfield-button-disabled-background-color);
    border-color: var(--amplify-components-searchfield-button-disabled-border-color);
    color: var(--amplify-components-searchfield-button-disabled-color);
  }
  .amplify-searchfield__search:focus {
    background-color: var(--amplify-components-searchfield-button-focus-background-color);
    border-color: var(--amplify-components-searchfield-button-focus-border-color);
    color: var(--amplify-components-searchfield-button-focus-color);
  }
  .amplify-searchfield__search:hover {
    background-color: var(--amplify-components-searchfield-button-hover-background-color);
    border-color: var(--amplify-components-searchfield-button-hover-border-color);
    color: var(--amplify-components-searchfield-button-hover-color);
  }
  
  .amplify-select__wrapper {
    flex: var(--amplify-components-select-wrapper-flex);
    display: var(--amplify-components-select-wrapper-display);
    position: var(--amplify-components-select-wrapper-position);
    cursor: var(--amplify-components-select-wrapper-cursor);
    align-self: stretch;
  }
  
  .amplify-select__icon-wrapper {
    align-items: var(--amplify-components-select-icon-wrapper-align-items);
    position: var(--amplify-components-select-icon-wrapper-position);
    top: var(--amplify-components-select-icon-wrapper-top);
    right: var(--amplify-components-select-icon-wrapper-right);
    transform: var(--amplify-components-select-icon-wrapper-transform);
    pointer-events: var(--amplify-components-select-icon-wrapper-pointer-events);
  }
  
  .amplify-select {
    box-sizing: border-box;
    color: var(--amplify-components-fieldcontrol-color);
    font-size: var(--amplify-components-fieldcontrol-font-size);
    line-height: var(--amplify-components-fieldcontrol-line-height);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
    transition: all var(--amplify-components-fieldcontrol-transition-duration);
    width: 100%;
    border-color: var(--amplify-components-fieldcontrol-border-color);
    border-radius: var(--amplify-components-fieldcontrol-border-radius);
    border-style: var(--amplify-components-fieldcontrol-border-style);
    border-width: var(--amplify-components-fieldcontrol-border-width);
    outline-color: var(--amplify-components-fieldcontrol-outline-color);
    outline-style: var(--amplify-components-fieldcontrol-outline-style);
    outline-width: var(--amplify-components-fieldcontrol-outline-width);
    outline-offset: var(--amplify-components-fieldcontrol-outline-offset);
    min-width: var(--amplify-components-select-min-width);
    -webkit-padding-end: var(--amplify-components-select-padding-inline-end);
            padding-inline-end: var(--amplify-components-select-padding-inline-end);
    white-space: var(--amplify-components-select-white-space);
  }
  .amplify-select:focus {
    border-color: var(--amplify-components-fieldcontrol-focus-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  }
  .amplify-select--small {
    font-size: var(--amplify-components-fieldcontrol-small-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-small-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-small-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-small-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-small-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-small-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-small-padding-inline-end);
  }
  .amplify-select--large {
    font-size: var(--amplify-components-fieldcontrol-large-font-size);
    -webkit-padding-before: var(--amplify-components-fieldcontrol-large-padding-block-start);
            padding-block-start: var(--amplify-components-fieldcontrol-large-padding-block-start);
    -webkit-padding-after: var(--amplify-components-fieldcontrol-large-padding-block-end);
            padding-block-end: var(--amplify-components-fieldcontrol-large-padding-block-end);
    -webkit-padding-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
            padding-inline-start: var(--amplify-components-fieldcontrol-large-padding-inline-start);
    -webkit-padding-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
            padding-inline-end: var(--amplify-components-fieldcontrol-large-padding-inline-end);
  }
  .amplify-select--error {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
  }
  .amplify-select--error:focus {
    border-color: var(--amplify-components-fieldcontrol-error-border-color);
    box-shadow: var(--amplify-components-fieldcontrol-error-focus-box-shadow);
  }
  .amplify-select[disabled] {
    color: var(--amplify-components-fieldcontrol-disabled-color);
    cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
    border-color: var(--amplify-components-fieldcontrol-disabled-border-color);
    background-color: var(--amplify-components-fieldcontrol-disabled-background-color);
  }
  .amplify-select--quiet {
    -webkit-border-before: var(--amplify-components-fieldcontrol-quiet-border-block-start);
            border-block-start: var(--amplify-components-fieldcontrol-quiet-border-block-start);
    -webkit-border-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
            border-inline-start: var(--amplify-components-fieldcontrol-quiet-border-inline-start);
    -webkit-border-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
            border-inline-end: var(--amplify-components-fieldcontrol-quiet-border-inline-end);
    border-radius: var(--amplify-components-fieldcontrol-quiet-border-radius);
  }
  .amplify-select--quiet:focus {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-focus-border-block-end-color);
    box-shadow: var(--amplify-components-fieldcontrol-quiet-focus-box-shadow);
  }
  .amplify-select--quiet[aria-invalid=true] {
    border-block-end-color: var(--amplify-components-fieldcontrol-quiet-error-border-block-end-color);
  }
  .amplify-select--quiet[aria-invalid=true]:focus {
    box-shadow: var(--amplify-components-fieldcontrol-quiet-error-focus-box-shadow);
  }
  .amplify-select option {
    color: initial;
  }
  .amplify-select option[disabled=""] {
    color: var(--amplify-components-select-option-disabled-color);
  }
  @-moz-document url-prefix() {
    .amplify-select option {
      background-color: var(--amplify-components-select-option-background-color);
      color: var(--amplify-components-select-option-color);
    }
  }
  .amplify-select--small {
    min-width: var(--amplify-components-select-small-min-width);
  }
  .amplify-select--large {
    min-width: var(--amplify-components-select-large-min-width);
  }
  
  .amplify-selectfield {
    flex-direction: var(--amplify-components-selectfield-flex-direction);
    --amplify-components-fieldcontrol-border-color: var(
      --amplify-components-selectfield-border-color
    );
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-selectfield-color
    );
    --amplify-components-fieldcontrol-font-size: var(
      --amplify-components-selectfield-font-size
    );
    --amplify-components-fieldcontrol-focus-border-color: var(
      --amplify-components-selectfield-focus-border-color
    );
    --amplify-components-field-label-color: var(
      --amplify-components-selectfield-label-color
    );
  }
  
  .amplify-sliderfield {
    flex-direction: column;
  }
  
  .amplify-sliderfield__label {
    display: flex;
    justify-content: space-between;
  }
  
  .amplify-sliderfield__root {
    align-items: center;
    box-sizing: content-box;
    display: flex;
    padding-block: var(--amplify-components-sliderfield-padding-block);
    position: relative;
    touch-action: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    --amplify-internal-sliderfield-root-height: var(
      --amplify-components-sliderfield-thumb-height
    );
    --amplify-internal-sliderfield-root-width: var(
      --amplify-components-sliderfield-thumb-height
    );
  }
  .amplify-sliderfield__root[data-disabled] {
    cursor: not-allowed;
  }
  .amplify-sliderfield__root--horizontal {
    height: var(--amplify-internal-sliderfield-root-height);
  }
  .amplify-sliderfield__root--vertical {
    flex-direction: column;
    width: var(--amplify-internal-sliderfield-root-width);
  }
  .amplify-sliderfield__root--large {
    --amplify-internal-sliderfield-root-height: var(
      --amplify-components-sliderfield-large-thumb-height
    );
    --amplify-internal-sliderfield-root-width: var(
      --amplify-components-sliderfield-large-thumb-height
    );
  }
  .amplify-sliderfield__root--small {
    --amplify-internal-sliderfield-root-height: var(
      --amplify-components-sliderfield-small-thumb-height
    );
    --amplify-internal-sliderfield-root-width: var(
      --amplify-components-sliderfield-small-thumb-height
    );
  }
  
  .amplify-sliderfield__track {
    position: relative;
    flex-grow: 1;
    border-radius: var(--amplify-components-sliderfield-track-border-radius);
    background-color: var(--amplify-components-sliderfield-track-background-color);
    --amplify-internal-sliderfield-track-height: var(
      --amplify-components-sliderfield-track-height
    );
    --amplify-internal-sliderfield-track-min-width: var(
      --amplify-components-sliderfield-track-min-width
    );
    --amplify-internal-sliderfield-track-width: var(
      --amplify-components-sliderfield-track-height
    );
    --amplify-internal-sliderfield-track-min-height: var(
      --amplify-components-sliderfield-track-min-width
    );
  }
  .amplify-sliderfield__track--horizontal {
    height: var(--amplify-internal-sliderfield-track-height);
    min-width: var(--amplify-internal-sliderfield-track-min-width);
  }
  .amplify-sliderfield__track--vertical {
    width: var(--amplify-internal-sliderfield-track-width);
    min-height: var(--amplify-internal-sliderfield-track-min-height);
  }
  .amplify-sliderfield__track--large {
    --amplify-internal-sliderfield-track-height: var(
      --amplify-components-sliderfield-large-track-height
    );
    --amplify-internal-sliderfield-track-width: var(
      --amplify-components-sliderfield-large-track-height
    );
  }
  .amplify-sliderfield__track--small {
    --amplify-internal-sliderfield-track-height: var(
      --amplify-components-sliderfield-small-track-height
    );
    --amplify-internal-sliderfield-track-width: var(
      --amplify-components-sliderfield-small-track-height
    );
  }
  
  .amplify-sliderfield__range {
    position: absolute;
    border-radius: var(--amplify-components-sliderfield-range-border-radius);
    background-color: var(--amplify-components-sliderfield-range-background-color);
  }
  .amplify-sliderfield__range[data-disabled] {
    background-color: var(--amplify-components-sliderfield-range-disabled-background-color);
  }
  .amplify-sliderfield__range--horizontal {
    height: 100%;
  }
  .amplify-sliderfield__range--vertical {
    width: 100%;
  }
  
  .amplify-sliderfield__thumb {
    display: block;
    width: var(--amplify-components-sliderfield-thumb-width);
    height: var(--amplify-components-sliderfield-thumb-height);
    background-color: var(--amplify-components-sliderfield-thumb-background-color);
    box-shadow: var(--amplify-components-sliderfield-thumb-box-shadow);
    border-radius: var(--amplify-components-sliderfield-thumb-border-radius);
    border-width: var(--amplify-components-sliderfield-thumb-border-width);
    border-color: var(--amplify-components-sliderfield-thumb-border-color);
    border-style: var(--amplify-components-sliderfield-thumb-border-style);
  }
  .amplify-sliderfield__thumb:hover {
    background-color: var(--amplify-components-sliderfield-thumb-hover-background-color);
    border-color: var(--amplify-components-sliderfield-thumb-hover-border-color);
  }
  .amplify-sliderfield__thumb:focus {
    border-color: var(--amplify-components-sliderfield-thumb-focus-border-color);
    box-shadow: var(--amplify-components-sliderfield-thumb-focus-box-shadow);
  }
  .amplify-sliderfield__thumb[data-disabled] {
    background-color: var(--amplify-components-sliderfield-thumb-disabled-background-color);
    border-color: var(--amplify-components-sliderfield-thumb-disabled-border-color);
    box-shadow: var(--amplify-components-sliderfield-thumb-disabled-box-shadow);
  }
  .amplify-sliderfield__thumb--large {
    width: var(--amplify-components-sliderfield-large-thumb-width);
    height: var(--amplify-components-sliderfield-large-thumb-height);
  }
  .amplify-sliderfield__thumb--small {
    width: var(--amplify-components-sliderfield-small-thumb-width);
    height: var(--amplify-components-sliderfield-small-thumb-height);
  }
  
  .amplify-stepperfield {
    flex-direction: var(--amplify-components-stepperfield-flex-direction);
    --amplify-components-fieldcontrol-border-color: var(
      --amplify-components-stepperfield-border-color
    );
    --amplify-components-button-border-color: var(
      --amplify-components-stepperfield-border-color
    );
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-stepperfield-input-color
    );
    --amplify-components-fieldcontrol-font-size: var(
      --amplify-components-stepperfield-input-font-size
    );
    --amplify-components-button-color: var(
      --amplify-components-stepperfield-button-color
    );
    --amplify-components-button-active-color: var(
      --amplify-components-stepperfield-button-active-color
    );
    --amplify-components-button-active-background-color: var(
      --amplify-components-stepperfield-button-active-background-color
    );
    --amplify-components-button-focus-color: var(
      --amplify-components-stepperfield-button-focus-color
    );
    --amplify-components-button-focus-background-color: var(
      --amplify-components-stepperfield-button-focus-background-color
    );
    --amplify-components-button-disabled-color: var(
      --amplify-components-stepperfield-button-disabled-color
    );
    --amplify-components-button-disabled-background-color: var(
      --amplify-components-stepperfield-button-disabled-background-color
    );
    --amplify-components-button-hover-color: var(
      --amplify-components-stepperfield-button-hover-color
    );
    --amplify-components-button-hover-background-color: var(
      --amplify-components-stepperfield-button-hover-background-color
    );
  }
  
  .amplify-stepperfield__button--decrease,
  .amplify-stepperfield__button--increase {
    background-color: var(--amplify-components-stepperfield-button-background-color);
  }
  .amplify-stepperfield__button--decrease--disabled,
  .amplify-stepperfield__button--increase--disabled {
    background-color: var(--amplify-components-stepperfield-button-disabled-background-color);
  }
  .amplify-stepperfield__button--decrease--quiet,
  .amplify-stepperfield__button--increase--quiet {
    border-width: 0 0 var(--amplify-components-button-border-width) 0;
    border-radius: 0;
  }
  
  .amplify-stepperfield__button--decrease[data-invalid=true] {
    -webkit-border-end: none;
            border-inline-end: none;
  }
  
  .amplify-stepperfield__button--increase[data-invalid=true] {
    -webkit-border-start: none;
            border-inline-start: none;
  }
  
  .amplify-stepperfield__input {
    -moz-appearance: textfield;
    text-align: var(--amplify-components-stepperfield-input-text-align);
  }
  .amplify-stepperfield__input::-webkit-outer-spin-button, .amplify-stepperfield__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .amplify-stepperfield__input:not(:focus, [aria-invalid=true]) {
    -webkit-border-start: none;
            border-inline-start: none;
    -webkit-border-end: none;
            border-inline-end: none;
  }
  
  .amplify-switchfield {
    display: inline-block;
    font-size: var(--amplify-components-switchfield-font-size);
  }
  .amplify-switchfield--small {
    font-size: var(--amplify-components-switchfield-small-font-size);
  }
  .amplify-switchfield--large {
    font-size: var(--amplify-components-switchfield-large-font-size);
  }
  
  .amplify-switch__wrapper {
    display: inline-flex;
    align-items: center;
  }
  .amplify-switch__wrapper--start {
    flex-direction: row;
  }
  .amplify-switch__wrapper--end {
    flex-direction: row-reverse;
  }
  .amplify-switch__wrapper--top {
    flex-direction: column;
  }
  .amplify-switch__wrapper--bottom {
    flex-direction: column-reverse;
  }
  
  .amplify-switch-track {
    display: inline-flex;
    justify-content: flex-start;
    box-sizing: content-box;
    border-radius: var(--amplify-components-switchfield-track-border-radius);
    padding: var(--amplify-components-switchfield-track-padding);
    width: var(--amplify-components-switchfield-track-width);
    height: var(--amplify-components-switchfield-track-height);
    transition-duration: var(--amplify-components-switchfield-track-transition-duration);
    background-color: var(--amplify-components-switchfield-track-background-color);
  }
  .amplify-switch-track--checked {
    background-color: var(--amplify-components-switchfield-track-checked-background-color);
  }
  .amplify-switch-track--disabled {
    opacity: var(--amplify-components-switchfield-disabled-opacity);
    cursor: not-allowed;
  }
  .amplify-switch-track--focused {
    box-shadow: var(--amplify-components-switchfield-focused-shadow);
  }
  .amplify-switch-track--error {
    background-color: var(--amplify-components-switchfield-track-error-background-color);
  }
  
  .amplify-switch-thumb {
    background-color: var(--amplify-components-switchfield-thumb-background-color);
    transition-duration: var(--amplify-components-switchfield-thumb-transition-duration);
    border-radius: var(--amplify-components-switchfield-thumb-border-radius);
    width: var(--amplify-components-switchfield-thumb-width);
    height: var(--amplify-components-switchfield-thumb-width);
    border-color: var(--amplify-components-switchfield-thumb-border-color);
    overflow-wrap: break-word;
  }
  .amplify-switch-thumb--checked {
    transform: var(--amplify-components-switchfield-thumb-checked-transform);
  }
  .amplify-switch-thumb--disabled {
    cursor: not-allowed;
  }
  
  .amplify-switch-label {
    padding: var(--amplify-components-switchfield-label-padding);
    cursor: pointer;
  }
  
  .amplify-table {
    /**
     * Default Table primitive stylings
     */
    border-collapse: var(--amplify-components-table-border-collapse);
    display: var(--amplify-components-table-display);
    width: var(--amplify-components-table-width);
    --amplify-internal-table-caption-font-size: var(
      --amplify-components-table-caption-font-size
    );
    --amplify-internal-table-th-font-size: var(
      --amplify-components-table-header-font-size
    );
    --amplify-internal-table-th-padding: var(
      --amplify-components-table-header-padding
    );
    --amplify-internal-table-td-font-size: var(
      --amplify-components-table-data-font-size
    );
    --amplify-internal-table-td-padding: var(
      --amplify-components-table-data-padding
    );
    --amplify-internal-table-td-border-width: var(
        --amplify-components-table-data-border-width
      )
      0px var(--amplify-components-table-data-border-width) 0px;
    --amplify-internal-table-th-border-width: var(
        --amplify-components-table-header-border-width
      )
      0px var(--amplify-components-table-header-border-width) 0px;
    /**
     * Data attribute stylings
     */
  }
  .amplify-table--small {
    --amplify-internal-table-caption-font-size: var(
      --amplify-components-table-caption-small-font-size
    );
    --amplify-internal-table-th-font-size: var(
      --amplify-components-table-header-small-font-size
    );
    --amplify-internal-table-th-padding: var(
      --amplify-components-table-header-small-padding
    );
    --amplify-internal-table-td-font-size: var(
      --amplify-components-table-data-small-font-size
    );
    --amplify-internal-table-td-padding: var(
      --amplify-components-table-data-small-padding
    );
  }
  .amplify-table--large {
    --amplify-internal-table-caption-font-size: var(
      --amplify-components-table-caption-large-font-size
    );
    --amplify-internal-table-th-font-size: var(
      --amplify-components-table-header-large-font-size
    );
    --amplify-internal-table-th-padding: var(
      --amplify-components-table-header-large-padding
    );
    --amplify-internal-table-td-font-size: var(
      --amplify-components-table-data-large-font-size
    );
    --amplify-internal-table-td-padding: var(
      --amplify-components-table-data-large-padding
    );
  }
  .amplify-table--bordered {
    --amplify-internal-table-td-border-width: var(
        --amplify-components-table-data-border-width
      )
      var(--amplify-components-table-data-border-width)
      var(--amplify-components-table-data-border-width)
      var(--amplify-components-table-data-border-width);
    --amplify-internal-table-th-border-width: var(
        --amplify-components-table-header-border-width
      )
      var(--amplify-components-table-header-border-width)
      var(--amplify-components-table-header-border-width)
      var(--amplify-components-table-header-border-width);
  }
  .amplify-table__caption {
    caption-side: var(--amplify-components-table-caption-caption-side);
    color: var(--amplify-components-table-caption-color);
    display: var(--amplify-components-table-caption-display);
    font-size: var(--amplify-internal-table-caption-font-size);
    text-align: var(--amplify-components-table-caption-text-align);
    word-break: var(--amplify-components-table-caption-word-break);
  }
  .amplify-table__head {
    display: var(--amplify-components-table-head-display);
    vertical-align: var(--amplify-components-table-head-vertical-align);
  }
  .amplify-table__body {
    display: var(--amplify-components-table-body-display);
    vertical-align: var(--amplify-components-table-body-vertical-align);
  }
  .amplify-table__foot {
    display: var(--amplify-components-table-foot-display);
    vertical-align: var(--amplify-components-table-foot-vertical-align);
  }
  .amplify-table__row {
    display: var(--amplify-components-table-row-display);
    vertical-align: var(--amplify-components-table-row-vertical-align);
  }
  .amplify-table__th {
    border-color: var(--amplify-components-table-header-border-color);
    border-style: var(--amplify-components-table-header-border-style);
    border-width: var(--amplify-internal-table-th-border-width);
    color: var(--amplify-components-table-header-color);
    display: var(--amplify-components-table-header-display);
    font-size: var(--amplify-internal-table-th-font-size);
    font-weight: var(--amplify-components-table-header-font-weight);
    padding: var(--amplify-internal-table-th-padding);
    vertical-align: var(--amplify-components-table-header-vertical-align);
  }
  .amplify-table__th:first-child {
    border-left-width: var(--amplify-components-table-header-border-width);
  }
  .amplify-table__th:last-child {
    border-right-width: var(--amplify-components-table-header-border-width);
  }
  .amplify-table__td {
    border-color: var(--amplify-components-table-data-border-color);
    border-style: var(--amplify-components-table-data-border-style);
    border-width: var(--amplify-internal-table-td-border-width);
    color: var(--amplify-components-table-data-color);
    display: var(--amplify-components-table-data-display);
    font-size: var(--amplify-internal-table-td-font-size);
    font-weight: var(--amplify-components-table-data-font-weight);
    padding: var(--amplify-internal-table-td-padding);
    vertical-align: var(--amplify-components-table-data-vertical-align);
  }
  .amplify-table__td:first-child {
    border-left-width: var(--amplify-components-table-data-border-width);
  }
  .amplify-table__td:last-child {
    border-right-width: var(--amplify-components-table-data-border-width);
  }
  .amplify-table[data-variation=striped] .amplify-table__row:not(.amplify-table__head *):nth-child(odd) {
    background-color: var(--amplify-components-table-row-striped-background-color);
  }
  .amplify-table[data-highlightonhover=true] .amplify-table__row:not(.amplify-table__head *):hover {
    background-color: var(--amplify-components-table-row-hover-background-color);
  }
  
  .amplify-tabs {
    background-color: var(--amplify-components-tabs-background-color);
    box-shadow: var(--amplify-components-tabs-box-shadow);
    border-width: 0 0 var(--amplify-components-tabs-border-width) 0;
    border-style: var(--amplify-components-tabs-border-style);
    border-color: var(--amplify-components-tabs-border-color);
    gap: var(--amplify-components-tabs-gap);
  }
  .amplify-tabs[data-indicator-position=top] {
    border-width: var(--amplify-components-tabs-border-width) 0 0 0;
  }
  
  .amplify-tabs-item {
    background-color: var(--amplify-components-tabs-item-background-color);
    box-sizing: border-box;
    color: var(--amplify-components-tabs-item-color);
    font-size: var(--amplify-components-tabs-item-font-size);
    font-weight: var(--amplify-components-tabs-item-font-weight);
    padding: var(--amplify-components-tabs-item-padding-vertical) var(--amplify-components-tabs-item-padding-horizontal);
    text-align: var(--amplify-components-tabs-item-text-align);
    transition: all var(--amplify-components-tabs-item-transition-duration);
    border-width: 0 0 var(--amplify-components-tabs-item-border-width) 0;
    border-style: var(--amplify-components-tabs-item-border-style);
    border-color: var(--amplify-components-tabs-item-border-color);
    margin-bottom: calc(-1 * var(--amplify-components-tabs-item-border-width));
  }
  [data-indicator-position=top] > .amplify-tabs-item {
    border-width: var(--amplify-components-tabs-border-width) 0 0 0;
    margin-top: calc(-1 * var(--amplify-components-tabs-item-border-width));
    margin-bottom: 0;
  }
  .amplify-tabs-item[data-state=active] {
    color: var(--amplify-components-tabs-item-active-color);
    border-color: var(--amplify-components-tabs-item-active-border-color);
    background-color: var(--amplify-components-tabs-item-active-background-color);
    transition-property: none;
  }
  .amplify-tabs-item:hover {
    color: var(--amplify-components-tabs-item-hover-color);
    cursor: pointer;
  }
  .amplify-tabs-item:focus {
    color: var(--amplify-components-tabs-item-focus-color);
  }
  .amplify-tabs-item:focus-visible {
    outline: var(--amplify-components-tabs-item-border-width) var(--amplify-components-tabs-item-border-style) var(--amplify-components-tabs-item-border-color);
  }
  .amplify-tabs-item:active {
    color: var(--amplify-components-tabs-item-active-color);
  }
  .amplify-tabs-item[data-disabled] {
    background-color: var(--amplify-components-tabs-item-disabled-background-color);
    color: var(--amplify-components-tabs-item-disabled-color);
    cursor: not-allowed;
  }
  .amplify-tabs-item[data-spacing=equal] {
    flex: 1 1 0;
  }
  .amplify-tabs-item[data-spacing=relative] {
    flex-grow: 1;
  }
  
  .amplify-textareafield {
    flex-direction: column;
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-textareafield-color
    );
    --amplify-components-fieldcontrol-border-color: var(
      --amplify-components-textareafield-border-color
    );
    --amplify-components-fieldcontrol-focus-border-color: var(
      --amplify-components-textareafield-focus-border-color
    );
  }
  
  .amplify-textfield {
    flex-direction: column;
    --amplify-components-fieldcontrol-color: var(
      --amplify-components-textfield-color
    );
    --amplify-components-fieldcontrol-border-color: var(
      --amplify-components-textfield-border-color
    );
    --amplify-components-fieldcontrol-font-size: var(
      --amplify-components-textfield-font-size
    );
    --amplify-components-fieldcontrol-focus-border-color: var(
      --amplify-components-textfield-focus-border-color
    );
  }
  
  .amplify-togglebutton {
    --amplify-internal-togglebutton-background-color: initial;
    --amplify-internal-togglebutton-border-color: var(
      --amplify-components-togglebutton-border-color
    );
    --amplify-internal-togglebutton-color: var(
      --amplify-components-togglebutton-color
    );
    background-color: var(--amplify-internal-togglebutton-background-color);
    border-color: var(--amplify-internal-togglebutton-border-color);
    color: var(--amplify-internal-togglebutton-color);
    --amplify-internal-togglebutton-focus-background-color: var(
      --amplify-internal-togglebutton-background-color
    );
    --amplify-internal-togglebutton-focus-border-color: var(
      --amplify-components-togglebutton-focus-border-color
    );
    --amplify-internal-togglebutton-focus-color: var(
      --amplify-components-togglebutton-focus-color
    );
    --amplify-internal-togglebutton-hover-background-color: var(
      --amplify-components-togglebutton-hover-background-color
    );
    --amplify-internal-togglebutton-hover-border-color: var(
      --amplify-internal-togglebutton-border-color
    );
    --amplify-internal-togglebutton-hover-color: var(
      --amplify-internal-togglebutton-color
    );
    --amplify-internal-togglebutton-active-background-color: var(
      --amplify-components-togglebutton-active-background-color
    );
    --amplify-internal-togglebutton-disabled-background-color: var(
      --amplify-components-togglebutton-disabled-background-color
    );
    --amplify-internal-togglebutton-disabled-border-color: var(
      --amplify-components-togglebutton-disabled-border-color
    );
    --amplify-internal-togglebutton-disabled-color: var(
      --amplify-components-togglebutton-disabled-color
    );
  }
  .amplify-togglebutton:focus {
    background-color: var(--amplify-internal-togglebutton-focus-background-color);
    border-color: var(--amplify-internal-togglebutton-focus-border-color);
    color: var(--amplify-internal-togglebutton-focus-color);
  }
  .amplify-togglebutton:hover {
    background-color: var(--amplify-internal-togglebutton-hover-background-color);
    border-color: var(--amplify-internal-togglebutton-hover-border-color);
    color: var(--amplify-internal-togglebutton-hover-color);
  }
  .amplify-togglebutton:active {
    background-color: var(--amplify-internal-togglebutton-active-background-color);
  }
  .amplify-togglebutton:disabled {
    background-color: var(--amplify-internal-togglebutton-disabled-background-color);
    border-color: var(--amplify-internal-togglebutton-disabled-border-color);
    color: var(--amplify-internal-togglebutton-disabled-color);
  }
  .amplify-togglebutton--pressed {
    --amplify-internal-togglebutton-border-color: var(
      --amplify-components-togglebutton-pressed-border-color
    );
    --amplify-internal-togglebutton-background-color: var(
      --amplify-components-togglebutton-pressed-background-color
    );
    --amplify-internal-togglebutton-color: var(
      --amplify-components-togglebutton-pressed-color
    );
    --amplify-internal-togglebutton-hover-background-color: var(
      --amplify-components-togglebutton-pressed-hover-background-color
    );
    --amplify-internal-togglebutton-disabled-background-color: var(
      --amplify-components-togglebutton-pressed-background-color
    );
    --amplify-internal-togglebutton-disabled-border-color: var(
      --amplify-components-togglebutton-pressed-border-color
    );
    --amplify-internal-togglebutton-disabled-color: var(
      --amplify-components-togglebutton-pressed-color
    );
  }
  .amplify-togglebutton--primary {
    --amplify-internal-togglebutton-primary-background-color: var(
      --amplify-components-togglebutton-primary-background-color
    );
    --amplify-internal-togglebutton-background-color: var(
      --amplify-internal-togglebutton-primary-background-color
    );
    --amplify-internal-togglebutton-primary-border-color: var(
      --amplify-components-togglebutton-border-color
    );
    --amplify-internal-togglebutton-border-color: var(
      --amplify-internal-togglebutton-primary-border-color
    );
    --amplify-internal-togglebutton-primary-color: var(
      --amplify-components-togglebutton-color
    );
    --amplify-internal-togglebutton-color: var(
      --amplify-internal-togglebutton-primary-color
    );
    border-width: var(--amplify-components-togglebutton-primary-border-width);
    --amplify-internal-togglebutton-primary-focus-background-color: var(
      --amplify-components-togglebutton-primary-focus-background-color
    );
    --amplify-internal-togglebutton-primary-focus-border-color: var(
      --amplify-components-togglebutton-primary-focus-border-color
    );
    --amplify-internal-togglebutton-primary-focus-color: var(
      --amplify-components-togglebutton-primary-focus-color
    );
    --amplify-internal-togglebutton-primary-focus-box-shadow: var(
      --amplify-components-togglebutton-primary-focus-box-shadow
    );
    --amplify-internal-togglebutton-focus-background-color: var(
      --amplify-internal-togglebutton-primary-focus-background-color
    );
    --amplify-internal-togglebutton-focus-border-color: var(
      --amplify-internal-togglebutton-primary-focus-border-color
    );
    --amplify-internal-togglebutton-focus-color: var(
      --amplify-internal-togglebutton-primary-focus-color
    );
    --amplify-internal-togglebutton-primary-hover-background-color: var(
      --amplify-components-togglebutton-primary-hover-background-color
    );
    --amplify-internal-togglebutton-primary-hover-border-color: var(
      --amplify-internal-togglebutton-primary-border-color
    );
    --amplify-internal-togglebutton-primary-hover-color: var(
      --amplify-components-togglebutton-primary-hover-color
    );
    --amplify-internal-togglebutton-hover-background-color: var(
      --amplify-internal-togglebutton-primary-hover-background-color
    );
    --amplify-internal-togglebutton-hover-border-color: var(
      --amplify-internal-togglebutton-primary-hover-border-color
    );
    --amplify-internal-togglebutton-hover-color: var(
      --amplify-internal-togglebutton-primary-hover-color
    );
    --amplify-internal-togglebutton-primary-disabled-background-color: var(
      --amplify-components-togglebutton-primary-disabled-background-color
    );
    --amplify-internal-togglebutton-primary-disabled-border-color: var(
      --amplify-components-togglebutton-primary-disabled-border-color
    );
    --amplify-internal-togglebutton-primary-disabled-color: var(
      --amplify-components-togglebutton-primary-disabled-color
    );
    --amplify-internal-togglebutton-disabled-background-color: var(
      --amplify-internal-togglebutton-primary-disabled-background-color
    );
    --amplify-internal-togglebutton-disabled-border-color: var(
      --amplify-internal-togglebutton-primary-disabled-border-color
    );
    --amplify-internal-togglebutton-disabled-color: var(
      --amplify-internal-togglebutton-primary-disabled-color
    );
  }
  .amplify-togglebutton--primary:focus {
    box-shadow: var(--amplify-internal-togglebutton-primary-focus-box-shadow);
  }
  .amplify-togglebutton--pressed {
    --amplify-internal-togglebutton-primary-background-color: var(
      --amplify-components-togglebutton-primary-pressed-background-color
    );
    --amplify-internal-togglebutton-primary-border-color: var(
      --amplify-components-togglebutton-primary-pressed-border-color
    );
    --amplify-internal-togglebutton-primary-color: var(
      --amplify-components-togglebutton-primary-pressed-color
    );
    --amplify-internal-togglebutton-primary-focus-background-color: var(
      --amplify-components-togglebutton-primary-pressed-focus-background-color
    );
    --amplify-internal-togglebutton-primary-focus-border-color: var(
      --amplify-components-togglebutton-primary-pressed-focus-border-color
    );
    --amplify-internal-togglebutton-primary-focus-color: var(
      --amplify-components-togglebutton-primary-pressed-focus-color
    );
    --amplify-internal-togglebutton-primary-hover-background-color: var(
      --amplify-components-togglebutton-primary-pressed-hover-background-color
    );
    --amplify-internal-togglebutton-primary-hover-border-color: var(
      --amplify-components-togglebutton-primary-pressed-hover-border-color
    );
    --amplify-internal-togglebutton-primary-hover-color: var(
      --amplify-components-togglebutton-primary-pressed-hover-color
    );
    --amplify-internal-togglebutton-primary-disabled-background-color: var(
      --amplify-components-togglebutton-primary-pressed-background-color
    );
    --amplify-internal-togglebutton-primary-disabled-border-color: var(
      --amplify-components-togglebutton-primary-pressed-border-color
    );
    --amplify-internal-togglebutton-primary-disabled-color: var(
      --amplify-components-togglebutton-primary-pressed-color
    );
  }
  .amplify-togglebutton--pressed:hover {
    --amplify-internal-togglebutton-primary-focus-box-shadow: var(
      --amplify-components-togglebutton-primary-pressed-hover-box-shadow
    );
  }
  .amplify-togglebutton--link {
    --amplify-internal-togglebutton-link-background-color: var(
      --amplify-components-togglebutton-link-background-color
    );
    --amplify-internal-togglebutton-link-color: var(
      --amplify-components-togglebutton-link-color
    );
    --amplify-internal-togglebutton-background-color: var(
      --amplify-internal-togglebutton-link-background-color
    );
    --amplify-internal-togglebutton-color: var(
      --amplify-internal-togglebutton-link-color
    );
    --amplify-internal-togglebutton-link-focus-background-color: var(
      --amplify-components-togglebutton-link-focus-background-color
    );
    --amplify-internal-togglebutton-link-focus-color: var(
      --amplify-components-togglebutton-link-focus-color
    );
    --amplify-internal-togglebutton-focus-background-color: var(
      --amplify-internal-togglebutton-link-focus-background-color
    );
    --amplify-internal-togglebutton-focus-color: var(
      --amplify-internal-togglebutton-link-focus-color
    );
    --amplify-internal-togglebutton-link-hover-background-color: var(
      --amplify-components-togglebutton-link-hover-background-color
    );
    --amplify-internal-togglebutton-link-hover-color: var(
      --amplify-components-togglebutton-link-hover-color
    );
    --amplify-internal-togglebutton-hover-background-color: var(
      --amplify-internal-togglebutton-link-hover-background-color
    );
    --amplify-internal-togglebutton-hover-color: var(
      --amplify-internal-togglebutton-link-hover-color
    );
    --amplify-internal-togglebutton-link-disabled-color: var(
      --amplify-components-togglebutton-link-disabled-color
    );
    --amplify-internal-togglebutton-link-disabled-background-color: var(
      --amplify-components-togglebutton-link-disabled-background-color
    );
    --amplify-internal-togglebutton-disabled-color: var(
      --amplify-internal-togglebutton-link-disabled-color
    );
    --amplify-internal-togglebutton-disabled-background-color: var(
      --amplify-internal-togglebutton-link-disabled-background-color
    );
  }
  .amplify-togglebutton--pressed {
    --amplify-internal-togglebutton-link-color: var(
      --amplify-components-togglebutton-link-pressed-color
    );
    --amplify-internal-togglebutton-link-background-color: var(
      --amplify-components-togglebutton-link-pressed-background-color
    );
    --amplify-internal-togglebutton-link-focus-background-color: var(
      --amplify-components-togglebutton-link-pressed-focus-background-color
    );
    --amplify-internal-togglebutton-link-focus-color: var(
      --amplify-components-togglebutton-link-pressed-focus-color
    );
    --amplify-internal-togglebutton-link-hover-background-color: var(
      --amplify-components-togglebutton-link-pressed-hover-background-color
    );
    --amplify-internal-togglebutton-link-hover-color: var(
      --amplify-components-togglebutton-link-pressed-hover-color
    );
    --amplify-internal-togglebutton-link-disabled-color: var(
      --amplify-components-togglebutton-link-pressed-color
    );
  }
  
  .amplify-togglebuttongroup {
    align-items: var(--amplify-components-togglebuttongroup-align-items);
    align-content: var(--amplify-components-togglebuttongroup-align-content);
    justify-content: var(--amplify-components-togglebuttongroup-justify-content);
    gap: 0;
  }
  
  .amplify-togglebuttongroup .amplify-togglebutton:focus, .amplify-togglebuttongroup .amplify-togglebutton.amplify-togglebutton--pressed {
    z-index: 2;
  }
  .amplify-togglebuttongroup .amplify-togglebutton:not(:first-of-type) {
    -webkit-margin-start: calc(-1 * var(--amplify-components-button-border-width));
            margin-inline-start: calc(-1 * var(--amplify-components-button-border-width));
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
  @supports not (border-start-start-radius: 0) {
    .amplify-togglebuttongroup .amplify-togglebutton:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .amplify-togglebuttongroup .amplify-togglebutton:not(:last-of-type) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }
  @supports not (border-end-end-radius: 0) {
    .amplify-togglebuttongroup .amplify-togglebutton:not(:last-of-type) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  
  [data-label-position=start] {
    flex-direction: row;
  }
  
  [data-label-position=end] {
    flex-direction: row-reverse;
  }
  
  [data-label-position=top] {
    flex-direction: column;
  }
  
  [data-label-position=bottom] {
    flex-direction: column-reverse;
  }
  
  .amplify-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    fill: transparent;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }